import React, { useEffect } from 'react'
import { VStack, Text } from '@chakra-ui/react'
import ReactGA from 'react-ga4'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { SubscriptionProductSlider } from 'features/treatments'
import { useIndexProducts } from '../../shared/queries/product'
import { useIndexActivePackOffers } from '../../shared/queries/packOffer'
import { DoctorsList } from '../../features/treatments/doctorsList'
import { Services } from '../../features/treatments/Services'

export const TreatmentProductItemsItems = ({ className = '', style = undefined }) => {
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
    isDefaultCouponToReturn: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading
  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }
  if (isLoading) {
    return null
  }
  const footnotes =
    productsToSale
      ?.filter(({ footnote }) => !!footnote)
      .map(({ id, footnote }, index) => ({
        id,
        footnote,
        index,
      })) || []
  return (
    <div className={`${styles.paddings} ${className}`} style={style}>
      <Label subTitle={'from a USP797 compliant US, sterile pharmacy'}>Weight loss</Label>
      <SubscriptionProductSlider items={productsToSale} footnotes={footnotes} />
    </div>
  )
}

export const TreatmentPlansPage = () => {
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading
  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }
  useEffect(() => {
    if (productsToSale && !isLoading) {
      const productItems = productsToSale.map((item, index) => ({
        item_id: item.id,
        item_name: item.title,
        affiliation: item.medName,
        index: index,
        price: item.priceInCents / 100 || item.totalPriceInCents / 100,
        ndc: item.ndc,
        sku: item.sku,
      }))

      const itemList = {
        items: productItems,
      }
      //https://developers.google.com/tag-platform/gtagjs/reference/events#view_item_list
      ReactGA.event('view_item_list', itemList)
    }
  }, [])

  if (isLoading) {
    return null
  }
  const footnotes =
    productsToSale
      ?.filter(({ footnote }) => !!footnote)
      .map(({ id, footnote }, index) => ({
        id,
        footnote,
        index,
      })) || []
  return (
    <section className={styles.wrapper}>
      <TreatmentProductItemsItems />

      <Services />
      <VStack w="100%" justifyContent="center" p="10px 7% 30px 7%" gap="20px">
        {footnotes.map(({ footnote, index }) => (
          <Text key={index} color="grey" fontSize="14" textAlign="start">
            {'*'.repeat(index + 1)} {footnote}
          </Text>
        ))}
      </VStack>
      <DoctorsList clear={false} className={styles.doctors} />
    </section>
  )
}
