import React, { useState, useEffect } from 'react'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Button, Link, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Input } from 'components/elements/input'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'
import { useBasketStore } from 'shared/stores/basketStore'
import { GOOGLE_AUTH_URL } from 'shared/constants'
import GoogleImage from 'shared/icons/socials/google.svg'
import { Label } from 'components/layout/label'
import { ForgotPasswordModal } from '../modal/forgotPasswordModal'
import { APP_PATHS } from 'paths'
import styles from './pageStyles.module.css'
import {
  useRequestResetPasswordMutation,
  useResendVerificationEmailMutation,
} from '../../shared/mutations/user'
import { NotificationPopup } from '../modal/notification-popup'

export const LoginPage = () => {
  const [logInActive, setLogIsActive] = useState(true)
  const [errorMessage, serErrorMessage] = useState('')
  const [isMfaRequired, setIsMfaRequired] = useState(false)
  const [attemptLoginToken, setAttemptLoginToken] = useState(null)
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false)
  const [resendVerificationEmailTimer, setResendVerificationEmailTimer] = useState(0)

  const {
    isOpen: isForgotPasswordOpen,
    onOpen: onOpenForgotPassword,
    onClose: onCloseForgotPassword,
  } = useDisclosure()
  const {
    isOpen: isPleaseResetEmailOpen,
    onOpen: onOpenPleaseResetEmail,
    onClose: onCloseResetCheckEmail,
  } = useDisclosure()

  const navigate = useNavigate()
  const { items } = useBasketStore()
  const { token } = useParams()
  const [params] = useSearchParams()
  const toast = useToast()
  const { mutate: onResetPassword } = useRequestResetPasswordMutation()
  const { mutate: resendVerificationEmail, isLoading: isResendVerificationMutationLoading } =
    useResendVerificationEmailMutation()
  const toBuy = params.get('toBuy')

  const onGoogleClick = () => {
    window.open(GOOGLE_AUTH_URL, '_self')
  }

  const { onLogin, onLogin2FA } = useUserContextStateDispatch()
  const handleAfterLogin = () => {
    const product = items?.[0]
    if (product && toBuy) {
      const paymentPath = generatePath(APP_PATHS.orderSubscription, {
        id: product.id,
      })
      navigate(paymentPath)
    } else {
      navigate(APP_PATHS.treatmentPlans)
    }
  }
  const handleForgotPassword = async (email) => {
    onResetPassword(
      { email },
      {
        onSettled: () => {
          onCloseForgotPassword()
        },
        onSuccess: () => {
          onOpenPleaseResetEmail()
        },
      }
    )
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email Address is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      await onLogin(
        values,
        () => {
          handleAfterLogin()
        },
        (token) => {
          setAttemptLoginToken(token)
          setIsMfaRequired(true)
        },
        (error) => {
          console.log('eerr', error)
          serErrorMessage(error.message)
          if (error?.error?.code === 'MNC-4') {
            setShouldVerifyEmail(true)
          }
        }
      )
    },
  })
  const formik2fa = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Token is required'),
    }),
    onSubmit: async (values) => {
      await onLogin2FA(
        {
          code: values.code,
          token: attemptLoginToken,
        },
        () => {
          handleAfterLogin()
        },
        (error) => {
          serErrorMessage(error.message)
        }
      )
    },
  })

  useEffect(() => {
    if (resendVerificationEmailTimer <= 0) {
      clearInterval(resendVerificationEmailTimer)
    }
  }, [resendVerificationEmailTimer])

  const handleResendVerificationEmail = async () => {
    setResendVerificationEmailTimer(15)

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(formik.values.email)) {
      toast({
        title: 'Please enter a valid email address.',
        status: 'error',
        position: 'top-right',
        duration: 10000,
        isClosable: true,
      })
      return
    }

    await resendVerificationEmail({
      email: formik.values.email,
    })

    setInterval(() => {
      setResendVerificationEmailTimer((prevTimer) => prevTimer - 1)
    }, 1000)
  }

  return (
    <div className={styles.wrapper}>
      <Label>Join the Maion Movement</Label>
      <div>
        <p>
          Sign in to Maion or{' '}
          <a
            href={`/registration/details${token ? `?token=${token}` : ''}`}
            className={styles.blue_text}
          >
            create an account
          </a>
        </p>
        {logInActive ? (
          isMfaRequired ? (
            <form onSubmit={formik2fa.handleSubmit}>
              <div className={styles.form}>
                <Input
                  name="email"
                  label="Email Address"
                  required
                  disabled
                  type="email"
                  color="white"
                  value={formik.values.email}
                  errorMessage={formik.errors.email}
                />
                <Input
                  name="password"
                  label="Password"
                  required
                  disabled
                  type="password"
                  color="white"
                  value={formik.values.password}
                  errorMessage={formik.errors.password}
                />
                <Input
                  name="code"
                  label="OTP Token"
                  required
                  type="text"
                  color="white"
                  value={formik2fa.values.code}
                  onChange={formik2fa.handleChange}
                  errorMessage={formik2fa.errors.code}
                />
                {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                {shouldVerifyEmail && (
                  <Box w="100%" mt={'-20px'}>
                    <Button
                      variant="link"
                      onClick={handleResendVerificationEmail}
                      disabled={
                        isResendVerificationMutationLoading || resendVerificationEmailTimer > 0
                      }
                    >
                      Resend verification email
                    </Button>
                  </Box>
                )}
                <Box w="100%" mt={'-20px'}>
                  <Button variant="link" onClick={onOpenForgotPassword}>
                    Forgot Password?
                  </Button>
                </Box>
                {/* <div style={{ alignSelf: 'center', marginTop: '-20px' }}>
                  <p>Create your account with phone number?</p>
                  <a>Sign in with a mobile</a>
                </div> */}
              </div>
              <div className={styles.buttons}>
                {/* <button
                  type="button"
                  className={styles.back}
                  onClick={() => setLogIsActive(false)}
                >
                  Back
                </button> */}
                <button type="submit" className={styles.blue_button}>
                  Log in
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.form}>
                <Input
                  name="email"
                  label="Email Address"
                  required
                  type="email"
                  color="white"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.email}
                />
                <Input
                  name="password"
                  label="Password"
                  required
                  type="password"
                  color="white"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.password}
                />
                {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                <Box w="100%" textAlign={'left'}>
                  <Text textColor="var(--footer) !important" fontSize={'14px'}>
                    Please check your email for a verification message from{' '}
                    <Text
                      as="span"
                      color="var(--help-color) !important"
                      textDecoration="underline"
                    >
                      info@maion.ai
                    </Text>
                  </Text>
                  <Text as="i" fontSize={'14px'}>
                    (don’t forget to check your SPAM folder too)
                  </Text>
                </Box>
                <Box w="100%" mt={'-20px'} textAlign={'left'}>
                  <Text textColor="var(--footer) !important" fontSize={'14px'} mb="20px">
                    If you haven’t received a verification email, please enter email address and
                    click the ‘Resend verification email’ link below
                  </Text>
                  <Link
                    onClick={handleResendVerificationEmail}
                    disabled={
                      isResendVerificationMutationLoading || resendVerificationEmailTimer > 0
                    }
                    style={{
                      color: 'var(--accent)',
                      fontWeight: 'bolder',
                      textDecoration: 'none',
                    }}
                  >
                    Resend verification email
                  </Link>
                </Box>
                <Box w="100%" mt={'-20px'} textAlign={'left'}>
                  <Link
                    // as="button"
                    onClick={onOpenForgotPassword}
                    style={{
                      color: 'var(--accent-hover)',
                      fontWeight: 'bolder',
                      textDecoration: 'none',
                    }}
                  >
                    Forgot Password?
                  </Link>
                </Box>
                {/* <div style={{ alignSelf: 'center', marginTop: '-20px' }}>
                  <p>Create your account with phone number?</p>
                  <a>Sign in with a mobile</a>
                </div> */}
              </div>
              <div className={styles.buttons}>
                {/* <button
                  type="button"
                  className={styles.back}
                  onClick={() => setLogIsActive(false)}
                >
                  Back
                </button> */}
                <button type="submit" className={styles.blue_button}>
                  Log in
                </button>
              </div>
            </form>
          )
        ) : (
          <>
            <input
              placeholder={'Email or username'}
              className={styles.input}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
            />
            {/* <div style={{ marginBottom: '40px' }}>
              <p>Create your account with phone number?</p>
              <a>Sign in with a mobile</a>
            </div> */}

            <button
              className={styles.blue_button}
              disabled={!formik.values.email}
              onClick={() => setLogIsActive(true)}
            >
              Continue
            </button>
          </>
        )}

        <span>or</span>

        <div className={styles.buttons_wrapper}>
          <button
            className={`${styles.other_social_button} ${styles.social_button}`}
            onClick={onGoogleClick}
          >
            <img src={GoogleImage} alt={'Google icon'} width={30} height={30} />
            <span>Continue with Google</span>
          </button>
        </div>
      </div>
      <ForgotPasswordModal
        email={isMfaRequired ? formik2fa.values.email : formik.values.email}
        isOpen={isForgotPasswordOpen}
        onClose={onCloseForgotPassword}
        onSubmitEmail={handleForgotPassword}
      />
      <NotificationPopup
        title="Please check your email"
        description="Your password was successfully reset and link was sent to your email. PLEASE CHECK SPAM FOLDER"
        isOpen={isPleaseResetEmailOpen}
        onClose={onCloseResetCheckEmail}
      />
    </div>
  )
}
