import React, { useCallback, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Box,
  Stack,
  HStack,
  Text,
  useToast,
  VStack,
  Spacer,
  Flex,
  Heading,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet-async'
import { useIndexProducts } from '../../shared/queries/product'
import { RxIcon } from '../../shared/icons/rxIcon'
import { StockBadge } from './StockBadge'
import { useBasketStore } from '../../shared/stores/basketStore'
import { Services } from './Services'
import { DoctorsList } from './doctorsList'
import { useIndexActivePackOffers } from '../../shared/queries/packOffer'
import styles from './styles.module.css'
import { useProcessUserActionMutation } from 'shared/mutations/userActions'
import { APP_PATHS } from 'paths'

export const SingleProduct = () => {
  const { id } = useParams()
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
    isDefaultCouponToReturn: true,
  })
  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})
  const { addItem, reduceItemAmount } = useBasketStore()
  const [product, setProduct] = useState(null)
  const toast = useToast()

  const { mutate: processUserAction } = useProcessUserActionMutation()

  useEffect(() => {
    if (id) {
      processUserAction({
        eventName: 'VIEWED_PRODUCT',
        productId: id,
      })
    }
  }, [])

  const productItem = (product) => ({
    currency: 'USD',
    value: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
    items: [
      {
        item_id: product?.id,
        item_name: product?.title,
        affiliation: product?.medName,
        price: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
        ndc: product?.ndc,
        sku: product?.sku,
      },
    ],
  })

  const addOneItemToBasket = useCallback(
    (product) => {
      addItem(product)
      reduceItemAmount(product.id, 1)
      processUserAction({
        eventName: 'ADDED_TO_CART',
        productId: product.id,
      })
      //https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
      ReactGA.event('add_to_cart', productItem(product))
      toast({
        position: 'top-right',
        status: 'success',
        title: `${product.title} added to your basket`,
        duration: 2000,
        isClosable: true,
      })
    },
    [addItem, reduceItemAmount]
  )
  useEffect(() => {
    if (isProductsLoading || isPackOffersLoading) {
      return
    }
    const product = products.find((product) => product.id === id)
    const packOffer = packOffers.find((packOffer) => packOffer.id === id)
    setProduct(product || packOffer)
  }, [isProductsLoading, isPackOffersLoading, products, packOffers])
  useEffect(() => {
    //https://developers.google.com/tag-platform/gtagjs/reference/events#view_item
    if (product) {
      ReactGA.event('view_item', productItem(product))
    }
  }, [product])

  if (isProductsLoading || isPackOffersLoading || product === null) {
    return <></>
  }

  if (!(isProductsLoading || isPackOffersLoading) && product === undefined) {
    return (
      <VStack marginTop={{ base: '30px', md: '180px' }}>
        <Box maxW="md" mx="auto" mt={10}>
          <Flex direction="column" align="center" p={6} gap={2}>
            <Heading as="h2" size="2xl" mb={4}>
              Error
            </Heading>
            <Box mb={4} fontSize="2xl" fontWeight="bold" color={'#646363'}>
              <Text fontWeight="bold">Product with ID:</Text>
              <Text color="gray.700">{id}</Text>
              <Text>not found.</Text>
            </Box>
            <Text mb={2} fontSize="2xl" fontWeight="semibold">
              Don&apos;t worry 😉
            </Text>
            <VStack fontSize="2xl" fontWeight="bold" color={'#646363'} spacing={0.25}>
              <Text>Please go to</Text>
              <Link to={APP_PATHS.treatmentPlans}>
                <Text color="blue.500">Treatment Plans</Text>
              </Link>
              <Text>and select one of the available.</Text>
            </VStack>
          </Flex>
        </Box>
      </VStack>
    )
  }

  const {
    title,
    footnote,
    imageUrl,
    fourthImageUrl,
    notice,
    status,
    isPrescriptionRequired,
    isRegisteredTradeMark,
    description,
    additionalDescriptionText,
    includedText,
    priceInCents,
    defaultCoupon,
    priceLabelBottomText,
  } = product

  const originalPriceInDollar = priceInCents / 100

  const priceWithCouponInDollar = () => {
    const discountAmount = defaultCoupon?.discount
      ? originalPriceInDollar * defaultCoupon?.discount
      : defaultCoupon?.discountAmount
    const totalAmountWithDiscount = (priceInCents - discountAmount) / 100
    return Math.round(totalAmountWithDiscount)
  }
  const isCouponDefault = !!defaultCoupon

  function generateFullDescription() {
    let fullDescription = description || ''
    const maxLength = 300 // Optimal 55 and 200 characters long, maximum of 300.

    // Function to extract text from HTML
    function extractTextFromHTML(htmlString) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')
      return Array.from(doc.body.querySelectorAll('li'))
        .map((li) => li.textContent.trim())
        .join('. ')
    }

    if (additionalDescriptionText) {
      fullDescription += '. '
      fullDescription += extractTextFromHTML(additionalDescriptionText)
    }

    if (!fullDescription.endsWith('.')) {
      fullDescription += '.'
    }

    if (fullDescription.length <= maxLength) return fullDescription
    return fullDescription.slice(0, maxLength - 3) + '...'
  }

  const keywords = `maion, ${title}, GLP-1, weight loss, USP797, sterile pharmacy, health, health care, pharmacy, care, subscription, prescription, medication, free delivery, delivery, support`

  return (
    <VStack marginTop={{ base: '30px', md: '180px' }}>
      <Helmet>
        <title>{`Maion - ${title}`}</title>
        <meta name="keywords" content={keywords} />
        <meta property="og:site_name" content="Maion" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Maion - ${title}`} />
        <meta property="og:description" content={generateFullDescription()} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={window.location.href} />
        {/* <meta name="twitter:card" content="product" />
        <meta name="twitter:site" content={window.location.href} />
        <meta name="twitter:image" content={imageUrl} /> */}
        <meta name="article:tag" content={keywords} />
      </Helmet>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-around"
        alignItems={{ base: 'center', md: 'flex-start' }}
        paddingBottom="50px"
        px={{ base: '0', md: '100px' }}
        w="100%"
      >
        <VStack alignItems="start" h="100%" maxWidth={{ base: '90vw', md: '40vw' }}>
          <Flex w="100%">
            <StockBadge status={status} />
            <Spacer />
            <button
              // eslint-disable-next-line react/no-unknown-property
              variant="contain"
              className={styles.button}
              onClick={() => addOneItemToBasket(product)}
              disabled={status !== 'IN_STOCK'}
            >
              Choose Plan
            </button>
          </Flex>
          <HStack alignSelf="start">
            <Text fontSize="44px" fontWeight="600">
              {title}
            </Text>
            {isRegisteredTradeMark && <Text>®</Text>}
            {isPrescriptionRequired && <RxIcon color="black" />}
          </HStack>
          <Text color="#D537A8" fontSize="22px" fontWeight="600" textAlign="start">
            {description}
            {footnote ? '*' : ''}
          </Text>
          {!isEmpty(additionalDescriptionText) && (
            <Box
              className={styles.insert_text}
              dangerouslySetInnerHTML={{ __html: additionalDescriptionText }}
            />
          )}
          {!isEmpty(includedText) && (
            <>
              <Text
                color="#D537A8"
                fontSize="22px"
                fontWeight="600"
                textAlign="start"
                style={{ 'margin-top': '16px' }}
              >
                What’s included
              </Text>
              <Box
                className={styles.insert_text}
                dangerouslySetInnerHTML={{ __html: includedText }}
              />
            </>
          )}
        </VStack>
        <Spacer />
        <VStack w={{ base: '90vw', md: '40vw' }} h="100%" alignItems="start">
          <Box
            rounded={4}
            bgGradient="linear(#C6B7E6, #E6E0F2)"
            borderRadius="15px"
            p={4}
            position="relative"
          >
            <img src={fourthImageUrl ?? imageUrl} alt={`${title} image`} />
            {isCouponDefault ? (
              <Box
                position="absolute"
                bottom="24px"
                right="0"
                bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
                h={{ base: '75px', lg: '80px', xl: '90px' }}
                w={{ base: '100px', lg: '110px', xl: '130px' }}
                color="white"
                borderLeftRadius={6}
                px={2}
                py={1}
              >
                <VStack alignItems="flex-start" spacing={0} className={styles.price_label}>
                  <Text fontSize={{ base: '8px', xl: '10px' }} fontWeight="bold">
                    Was:
                  </Text>
                  <Text
                    fontSize={{ base: 'sm', xl: 'base' }}
                    fontWeight="bold"
                    pl={3}
                    lineHeight="14px"
                    className={styles.cross}
                  >
                    ${originalPriceInDollar}
                  </Text>
                </VStack>
                <Text
                  fontSize={{ base: '2xl', lg: '3xl', xl: '4xl' }}
                  fontWeight="bold"
                  lineHeight={{ base: '24px', lg: '28px', xl: '34px' }}
                >
                  ${isCouponDefault ? priceWithCouponInDollar() : originalPriceInDollar}
                </Text>
                <Text fontSize={{ base: 'sm', xl: 'base' }} lineHeight="14px">
                  {priceLabelBottomText}
                </Text>
              </Box>
            ) : (
              <VStack
                position="absolute"
                bottom="24px"
                right="0"
                bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
                h={{ base: '55px', lg: '60px', xl: '65px' }}
                w={{ base: '100px', lg: '110px', xl: '130px' }}
                color="white"
                borderLeftRadius={6}
                px={2}
                py={1}
                justifyContent="center"
                spacing={0}
              >
                <Text
                  fontSize={{ base: '2xl', lg: '3xl', xl: '4xl' }}
                  fontWeight="bold"
                  lineHeight={{ base: '24px', lg: '28px', xl: '34px' }}
                >
                  ${originalPriceInDollar}
                </Text>
                {priceLabelBottomText && (
                  <Text fontSize={{ base: 'sm', xl: 'base' }} lineHeight="14px">
                    {priceLabelBottomText}
                  </Text>
                )}
              </VStack>
            )}
          </Box>
          {notice && (
            <Text color="grey" textAlign="start">
              <Text as="span" fontWeight="600">
                Safety Notice:
              </Text>{' '}
              {notice}
            </Text>
          )}
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant="contain"
            className={styles.button}
            style={{ marginTop: '36px' }}
            onClick={() => addOneItemToBasket(product)}
            disabled={status !== 'IN_STOCK'}
          >
            Choose Plan
          </button>
        </VStack>
      </Stack>
      <Services />
      <Box paddingInline="50px">
        {footnote && (
          <Text color="grey" fontSize="14" textAlign="start" margin="50px 0">
            *{footnote}
          </Text>
        )}
      </Box>
      <Box mt={!footnote && '50px !important'}>
        <DoctorsList clear={false} className={styles.doctors} />
      </Box>
    </VStack>
  )
}
