import React, { useEffect, useState } from 'react'
import { APP_PATHS } from 'paths'

import styles from './styles.module.css'
import { Button } from '../../../shared/components'

export const ReferralModal = ({ onConfirm }) => {
  const referralsModalDiscounts = [
    {
      textTop: '10%',
      textBottom: 'OFF',
      onButtonClick: () => onConfirm(),
    },
    {
      textTop: '10%',
      textBottom: 'OFF',
      onButtonClick: () => onConfirm(),
    },
    {
      textTop: '10%',
      textBottom: 'OFF',
      onButtonClick: () => onConfirm(),
    },
  ]
  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Choose your reward</p>
      <div className={styles.items_wrapper}>
        {referralsModalDiscounts.map((item, index) => (
          <div key={index} className={styles.item}>
            <div className={styles.item_inner}>
              <span className={styles.text_top}>{item.textTop}</span>
              <span className={styles.text_bottom}>{item.textBottom}</span>
            </div>
            <Button onClick={item.onButtonClick}>Select</Button>
          </div>
        ))}
      </div>
      <p className={styles.disclaimer}>
        Rewards will only be redeemed once the referred friend has made a purchase at Maion.
      </p>
    </div>
  )
}

export const ReferralCodeModal = ({ referralCode }) => {
  const [isCopied, setIsCopied] = useState(false)
  const currentHost = window.location.host
  const currentProtocol = window.location.protocol

  const referralLink = `${APP_PATHS.registrationStep1}?token=${referralCode}`
  const fullUrl = `${currentProtocol}//${currentHost}${referralLink}`
  // This is the function we wrote earlier
  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    } else {
      document.execCommand('copy', true, text)
    }
    setIsCopied(true)
  }
  useEffect(async () => {
    if (!isCopied) {
      await copyTextToClipboard(fullUrl)
    }
  }, [isCopied])
  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Here’s your link</p>
      <div className={styles.items_wrapper}>
        <a className={styles.link} href={referralLink}>
          {fullUrl}
        </a>
      </div>
      <p className={styles.disclaimer}>Your link has also been copied to your clipboard.</p>
    </div>
  )
}
