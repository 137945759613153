import React, { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import styles from './subscriptionProductsSlider.module.css'
import ArrowSlider from 'shared/icons/misc/arrowSlider.svg'

import { useBasketStore } from 'shared/stores/basketStore'
import {
  Box,
  Text,
  HStack,
  useToast,
  VStack,
  UnorderedList,
  ListItem,
  Image,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import ReactGA from 'react-ga4'
import RSSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useMediaQuery } from '../../../shared/hooks/useMediaQuery'
import { RxIcon } from '../../../shared/icons/rxIcon'
import { StockBadge } from '../StockBadge'
import { APP_PATHS } from '../../../paths'
import { useProcessUserActionMutation } from 'shared/mutations/userActions'
import DOMPurify from 'dompurify'

const Slide = ({ card, footnoteIndex }) => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const { addItem, reduceItemAmount } = useBasketStore()
  const navigate = useNavigate()
  const toast = useToast()
  const { mutate: processUserAction } = useProcessUserActionMutation()

  const productItem = (product) => ({
    currency: 'USD',
    value: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
    items: [
      {
        item_id: product?.id,
        item_name: product?.title,
        affiliation: product?.medName,
        price: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
        ndc: product?.ndc,
        sku: product?.sku,
      },
    ],
  })

  const addOneItemToBasket = useCallback(() => {
    addItem(card)
    reduceItemAmount(card.id, 1)
    processUserAction({
      eventName: 'ADDED_TO_CART',
      productId: card.id,
    })
    //https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
    ReactGA.event('add_to_cart', productItem(card))
    toast({
      position: 'top-right',
      status: 'success',
      title: `${card.title} added to your basket`,
      duration: 2000,
      isClosable: true,
    })
  }, [addItem, reduceItemAmount])
  const {
    status,
    id,
    title,
    imageUrl,
    thirdImageUrl,
    isPrescriptionRequired,
    isRegisteredTradeMark,
    description,
    additionalDescriptionText,
    priceInCents,
    defaultCoupon,
    priceLabelBottomText,
  } = card
  const paymentPath = generatePath(APP_PATHS.product, {
    id,
  })
  const originalPriceInDollar = priceInCents / 100

  const priceWithCouponInDollar = () => {
    const discountAmount = defaultCoupon?.discount
      ? originalPriceInDollar * defaultCoupon?.discount
      : defaultCoupon?.discountAmount
    const totalAmountWithDiscount = (priceInCents - discountAmount) / 100
    return Math.round(totalAmountWithDiscount)
  }
  const isCouponDefault = !!defaultCoupon

  const config = {
    ALLOWED_TAGS: ['ul', 'li'],
  }
  const cleanAdditionalDescriptionText = DOMPurify.sanitize(additionalDescriptionText, config)

  return (
    <VStack height={'100%'} justifyContent={'space-between'}>
      <VStack>
        <VStack
          rounded={4}
          bgGradient="linear-gradient(0deg, rgba(230, 224, 242, 0.80) 0%, rgba(198, 183, 230, 0.80) 100%)"
          w="100%"
          h={{ base: '400px', md: '450px' }}
          borderRadius="15px"
          cursor="pointer"
          onClick={() => navigate(paymentPath)}
          overflow="hidden"
          position={'relative'}
          mb={'24px'}
        >
          <HStack alignSelf="start" paddingInline={'24px'} paddingTop={'30px'} zIndex={1}>
            <Text color="white" fontSize={28} fontWeight={600} textAlign={'left'}>
              {title}
            </Text>
            {isRegisteredTradeMark && <Text color="white">®</Text>}
            {isPrescriptionRequired && <RxIcon />}
          </HStack>
          <Box paddingInline={'24px'} alignSelf={'start'} zIndex={1} width={'fit-content'}>
            <StockBadge status={status} />
          </Box>
          <Image
            src={thirdImageUrl ?? imageUrl}
            alt={`${title} image`}
            style={{
              objectFit: 'contain',
              position: 'absolute',
              height: '100%',
              maxWidth: '100%',
              bottom: 0,
              right: 0,
            }}
          />
          {isCouponDefault ? (
            <Box
              position="absolute"
              bottom="24px"
              left="0"
              bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
              h={{ base: '65px', lg: '65px', xl: '80px' }}
              maxW={{ base: '80px', lg: '90px', xl: '120px' }}
              color="white"
              borderRightRadius={6}
              px={2}
              py={1}
            >
              <VStack alignItems="flex-start" spacing={0} className={styles.price_label}>
                <Text fontSize={{ base: '8px', xl: '10px' }} fontWeight="bold">
                  Was:
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  pl={2}
                  lineHeight="12px"
                  className={styles.cross}
                >
                  ${originalPriceInDollar}
                </Text>
              </VStack>
              <Text
                fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}
                fontWeight="bold"
                m={0}
                lineHeight={{ base: '20px', lg: '24px', xl: '30px' }}
              >
                ${priceWithCouponInDollar()}
              </Text>
              <Text
                fontSize={{ base: '11px', xl: 'sm' }}
                lineHeight={{ base: '10px', xl: '12px' }}
              >
                {priceLabelBottomText}
              </Text>
            </Box>
          ) : (
            <VStack
              position="absolute"
              bottom="24px"
              left="0"
              bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
              h={{ base: '45px', lg: '55px', xl: '60px' }}
              maxW={{ base: '80px', lg: '90px', xl: '120px' }}
              color="white"
              borderRightRadius={6}
              px={2}
              py={1}
              justifyContent="center"
              spacing={0}
            >
              <Text
                fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}
                fontWeight="bold"
                lineHeight={{ base: '20px', lg: '24px', xl: '30px' }}
              >
                ${originalPriceInDollar}
              </Text>
              {priceLabelBottomText && (
                <Text fontSize={{ base: '11px', xl: 'sm' }} lineHeight="14px">
                  {priceLabelBottomText}
                </Text>
              )}
            </VStack>
          )}
        </VStack>
        <Box style={{ marginBottom: '24px' }}>
          <UnorderedList>
            <ListItem textAlign="start" fontWeight="500">
              {description} {footnoteIndex >= 0 ? '*'.repeat(footnoteIndex + 1) : ''}
            </ListItem>
          </UnorderedList>
          {!isEmpty(cleanAdditionalDescriptionText) && (
            <Box
              className={styles.insert_text}
              dangerouslySetInnerHTML={{ __html: cleanAdditionalDescriptionText }}
            />
          )}
        </Box>
      </VStack>
      <HStack
        mb={4}
        gap={{ base: '15px', xl: '30px' }}
        justifyContent={isMobile ? 'center' : 'start'}
        w={'100%'}
        marginTop={'auto'}
      >
        <button
          className={styles.button}
          // eslint-disable-next-line react/no-unknown-property
          variant={'outline'}
          onClick={() => navigate(paymentPath)}
        >
          Find Out More
        </button>
        <button
          // eslint-disable-next-line react/no-unknown-property
          variant="contain"
          className={styles.button}
          onClick={addOneItemToBasket}
          disabled={status !== 'IN_STOCK'}
        >
          Choose Plan
        </button>
      </HStack>
    </VStack>
  )
}

export const Slider = ({ items, footnotes }) => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const settings = {
    className: 'center',
    centerMode: false,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: isMobile ? 1 : 3,
    speed: 500,
    nextArrow: isMobile ? null : <img src={ArrowSlider} alt={'Next'} />,
    prevArrow: isMobile ? null : <img src={ArrowSlider} alt={'Previous'} />,
    // beforeChange: (current, next) => setSlideIndex(next),
  }

  // let sliderRef = useRef(null)
  // const [slideIndex, setSlideIndex] = useState(0)

  // const goTo = (index) => {
  // sliderRef.slickGoTo(index)
  // }

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          marginTop: '30px',
        }}
      >
        <Box
          sx={{
            '.slick-slider': {
              width: '84vw',
            },
            '.slick-slide': {
              marginBlock: isMobile ? '0.5%' : '1%',
              borderRadius: '15px',
              transition: '0.8s ease',
              height: '100%',
              display: 'flex',
              div: {
                width: '100%',
              },
            },
            '.slick-track': {
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              height: '810px',
            },
            '.slick-center': {
              transform: isMobile ? 'scale(1.05)' : 'scale(1.2)',
              zIndex: 100,
              opacity: 1,
              position: 'relative',
              background: 'white',
              boxShadow: '6px 6px 12px 0 rgba(0, 0, 0, 0.12)',
            },
            '.slick-list': {
              overflow: isMobile ? 'visible' : 'hidden',
            },
            '.slick-arrow': {
              display: isMobile ? 'none!important' : undefined,
              color: 'white',
              transition: '0.2s',
              _hover: {
                color: 'white',
              },
              _focus: {
                color: 'white',
              },
              _before: {
                transition: '0.2s',
              },
            },
            '.slick-prev': {
              display: isMobile ? 'none' : undefined,
              left: '-40px',
            },
            '.slick-next': {
              display: isMobile ? 'none' : undefined,

              right: '-40px',
              transform: 'scale(-1, 1)',
            },
          }}
        >
          <RSSlider
            // ref={(slider) => {
            //   sliderRef = slider
            // }}
            {...settings}
          >
            {items?.map((card) => (
              <Slide
                key={card.id}
                card={card}
                footnoteIndex={footnotes.find(({ id }) => id === card.id)?.index}
              />
            ))}
          </RSSlider>
        </Box>
        {/* <div className={styles.bottom_indicators}>
          {items?.map((i, index) => (
            <div key={i.id} onClick={() => goTo(index)} className={styles.invisible_item} />
          ))}
          {items?.length > 3 && (
            <div
              className={styles.bottom_indicator_item}
              style={{
                width: `${100 / items?.length}%`,
                left: `${slideIndex * (100 / items?.length)}%`,
              }}
            />
          )}
        </div> */}
      </div>
    </>
  )
}
