import React from 'react'
export const LanguageSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...args}
    >
      <path
        d="M6.99522 10.4672C7.64686 12.1041 9.26943 13.2675 11.1559 13.2675H13.202V17.0912C13.202 17.6442 12.7394 18.0956 12.1725 18.0956H5.76363L5.28468 18.566L4.38216 19.4496V18.0956H2.75307C2.18615 18.0956 1.72349 17.6442 1.72349 17.0912V11.4716C1.72349 10.9185 2.18615 10.4672 2.75307 10.4672H6.99196M8.31478 8.87793H2.75633C1.28689 8.87793 0.0976562 10.0381 0.0976562 11.4716V17.0912C0.0976562 18.5247 1.29015 19.6848 2.75633 19.6848V23.3052L6.44133 19.6848H12.1725C13.6419 19.6848 14.8311 18.5215 14.8311 17.0912V11.6782H11.1559C9.59199 11.6782 8.3213 10.4259 8.31804 8.87793H8.31478Z"
        fill="currentColor"
      />
      <path
        d="M21.3408 1.58925C21.9077 1.58925 22.3703 2.0406 22.3703 2.59366V8.21325C22.3703 8.76631 21.9077 9.21766 21.3408 9.21766H19.7117V10.5717L18.8092 9.68808L18.3302 9.21766H11.9214C11.3544 9.21766 10.8918 8.76631 10.8918 8.21325V2.59366C10.8918 2.0406 11.3544 1.58925 11.9214 1.58925H21.3375M21.3375 0H11.9214C10.4519 0 9.2627 1.16015 9.2627 2.59366V8.21325C9.2627 9.64676 10.4552 10.8069 11.9214 10.8069H17.6525L21.3375 14.4272V10.8069C22.8069 10.8069 23.9962 9.64676 23.9962 8.21325V2.59366C23.9962 1.16015 22.8069 0 21.3375 0Z"
        fill="currentColor"
      />
      <path
        d="M18.9169 7.54891L16.6297 2.84473L14.3457 7.54891"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M18.4446 6.52246H15.2744" stroke="black" strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M7.84616 17.3642L6.63086 16.0928"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M5.81592 12.1514H4.8352C4.09563 12.1514 3.49609 12.7362 3.49609 13.4577V15.3998C3.49609 16.1213 4.09563 16.7062 4.8352 16.7062H5.81592C6.55549 16.7062 7.15503 16.1213 7.15503 15.3998V13.4577C7.15503 12.7362 6.55549 12.1514 5.81592 12.1514Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
