import { create } from 'zustand'
import { uniqByKeepFirst } from '../utils/uniq'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useBasketStore = create(
  persist(
    (set, get) => ({
      items: [],
      addItem: (item) =>
        set(() => {
          // const items = uniqByKeepFirst([...get().items, item], (it) => it.id)
          const items = uniqByKeepFirst([item], (it) => it.id)
          return { items: items }
        }),
      removeItem: (id) => set(() => ({ items: get().items.filter((i) => i.id !== id) })),
      getItem: (id) => get().items.find((i) => i.id === id),
      reduceItemAmount: (id, amount) =>
        set(() => {
          const items = Array.from(get().items)
          const itemIndex = items.findIndex((i) => i.id === id)
          const item = items[itemIndex]
          if (item.amount && amount > 0) return { items: items }
          item.amount = (item.amount || 0) + amount
          item.totalPrice = item.priceInCents * item.amount
          return { items: items }
        }),
      clear: () => set({ items: [] }),
    }),
    {
      name: 'basket',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
