import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { isEmpty, size, trimStart } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'
import 'yup-phone'

import { Label } from 'components/layout/label'
import { useRegisterConsumerUserMutation } from 'shared/mutations/user'
import { APP_PATHS } from 'paths'

import styles from './detailPage.module.css'
import { InitialStep } from './steps/InitialStep'

function fullBirthDate({ month, day, year }) {
  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num)
  const birthDate = `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}T00:00:00.000Z`

  return birthDate
}

function isValidBirthdayDate() {
  const { month, day, year } = this.parent

  if (!month || !day || !year) {
    return false
  }

  return moment().diff(fullBirthDate({ month, day, year }), 'years') >= 18
}

const RegistrationIncompleteModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent borderRadius={'16px'}>
        <ModalHeader px={'46px'}>
          <Box display="flex" alignItems="center">
            <Text variant="h2" color="var(--accent)" mt={'20px'} fontSize={'24px'}>
              Account Creation Successful
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontSize={'14px'} lineHeight={'18px'} px={'46px'}>
          <Text>
            Please check your email for a verification message from{' '}
            <Text
              as="span"
              color="var(--help-color)"
              fontWeight="bold"
              textDecoration="underline"
            >
              info@maion.ai
            </Text>
          </Text>
          <Text as="i">(don’t forget to check your SPAM folder too)</Text>
        </ModalBody>
        <ModalFooter p="18px">
          <Button onClick={() => navigate(APP_PATHS.registration)} colorScheme="pink">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const RegistrationPage = () => {
  const toast = useToast()
  const [params] = useSearchParams()
  const token = params.get('token')
  const toBuy = params.get('toBuy')
  const [fieldErrors, setFieldErrors] = useState({})
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { mutate: onRegister } = useRegisterConsumerUserMutation({
    onSuccess: () => {
      onOpen()
    },
    onError: ({ error }) => {
      const { code, fields, message, context } = error

      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      } else if (code === 'MNC-1-1') {
        let errors = {}
        if (context?.phoneNumber) {
          errors.phoneNumber = 'User with such phone number already exists.'
        }
        if (context?.email) {
          errors.email = 'User with such email already exists.'
        }
        setFieldErrors(errors)
      } else if (code === 'MNC-12') {
        setFieldErrors({
          birthDate: message,
        })
      }
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
      password: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      month: '',
      day: '',
      year: '',
      acceptedTermsVersion: 0,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Legal First Name is required'),
      lastName: Yup.string().required('Legal Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email Address is required'),
      password: Yup.string()
        .required('Please Enter your new password')
        .test('test-regex-groups', 'Bad password', (value) => {
          if (!value) return false

          const regexs = [
            { regex: /(?=.*\d)/, message: 'One Number' },
            { regex: /(?=.*[a-z])/, message: 'One Lowercase' },
            { regex: /(?=.*[A-Z])/, message: 'One Uppercase' },
            {
              regex: /(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])/,
              message: 'One Special Case Character',
            },
          ]
          let messages = []
          if (size(value) < 9) {
            messages.push('9 Characters')
          }
          regexs.map(({ regex, message }) => {
            const matches = regex.exec(value)
            if (!matches) messages.push(message)
          })
          if (!isEmpty(messages)) {
            return new Yup.ValidationError(
              'Must Contain ' + messages.join(', '),
              value,
              'password'
            )
          }
          return true
        }),
      birthDate: Yup.date().when(['month', 'day', 'year'], {
        is: (month, day, year) => month && day && year,
        then: (schema) =>
          schema.test('is-correct-date', 'You must be 18-years old', isValidBirthdayDate),
        otherwise: (schema) => schema.required('Date of Birth is required'),
      }),
      phoneNumber: Yup.string()
        .phone('US', true, 'Invalid Phone Number')
        .required('Phone Number is required'),
      acceptedTermsVersion: Yup.boolean().oneOf(
        [true],
        'You must agree to the terms and conditions'
      ),
    }),
    onSubmit: (values) => {
      const { month, day, year } = values

      values.birthDate = fullBirthDate({ month, day, year })
      if (values.phoneNumber) {
        const cleanedNumber = values.phoneNumber.replace(/\s+/g, '')
        values.phoneNumber = cleanedNumber.startsWith('+1')
          ? cleanedNumber
          : `+1${cleanedNumber}`
      }
      onRegister({
        ...values,
        ...(!!token && { referralToken: token }),
      })
    },
  })

  return (
    <>
      <RegistrationIncompleteModal isOpen={isOpen} onClose={onClose} />
      <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
        <Label>Your Details</Label>
        <div className={styles.label}>
          Please{' '}
          <Link
            className={styles.href}
            to={`${APP_PATHS.registration}${toBuy ? '?toBuy=1' : ''}`}
          >
            login
          </Link>{' '}
          if you already have an account
        </div>
        <div className={styles.form}>
          <InitialStep formik={formik} fieldErrors={fieldErrors} />
        </div>

        <div className={styles.buttons}>
          <button
            type="submit"
            className={styles.continue}
            disabled={!isEmpty(formik.errors) || !formik.dirty}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  )
}

RegistrationPage.propTypes = {
  isFromScheduler: PropTypes.bool,
}
