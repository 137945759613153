import { CalendarSvg } from 'shared/icons/misc/calendar'
import { MoneySvg } from 'shared/icons/misc/money'
import { CircleSvg } from 'shared/icons/misc/circle'
import { HandsSvg } from 'shared/icons/misc/hands'
import { LanguageSvg } from 'shared/icons/misc/language'
import { BlockSvg } from 'shared/icons/misc/block'
import { UserSvg } from 'shared/icons/misc/user'
import { APP_PATHS } from '../../../paths'

export const MenuLinksLoggedIn = [
  {
    image: CalendarSvg,
    text: 'Treatment Plans',
    link: APP_PATHS.treatmentPlans,
  },
  {
    image: LanguageSvg,
    text: 'My Journey',
    link: APP_PATHS.myProfile,
  },
  {
    image: HandsSvg,
    text: 'Invite a Friend',
    link: APP_PATHS.myProfileReferralPage,
  },
  {
    image: MoneySvg,
    text: 'Billing & Payment Details',
    link: APP_PATHS.myProfileBillingPage,
  },
  {
    image: BlockSvg,
    text: 'Returns & Cancellations',
    link: APP_PATHS.myProfileReturnsPage,
  },
  {
    image: UserSvg,
    text: 'User Profile',
    link: APP_PATHS.myProfileUserPage,
  },
  // {
  //   image: CircleSvg,
  //   text: 'About Maion',
  //   link: APP_PATHS.myProfileAboutPage,
  // },
]
export const MenuLinksLoggedOut = [
  {
    image: CalendarSvg,
    text: 'Treatment Plans',
    link: APP_PATHS.treatmentPlans,
  },
  {
    image: HandsSvg,
    text: 'How it All Works',
    link: APP_PATHS.howitWorks,
  },
  {
    image: MoneySvg,
    text: 'FAQ',
    link: APP_PATHS.faq,
  },
  {
    image: LanguageSvg,
    text: 'Why Maion',
    link: APP_PATHS.whyMaion,
  },
  {
    image: CircleSvg,
    text: 'Contact',
    link: APP_PATHS.contact,
  },
  // {
  //   image: UserSvg,
  //   text: 'Referrals ',
  //   link: APP_PATHS.referrals,
  // },
]
