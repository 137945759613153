import React, { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { environment } from 'environments'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

import PaymentLogo from 'shared/icons/misc/creditCardOk.svg'
import styles from './styles.module.css'

export const PaymentComplete = ({ orderId, userId, profile, totalPrice }) => {
  const { email, firstName, lastName } = profile

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: environment.GOOGLE_TM_SUCCESS_PAYMENT,
      dataLayerName: 'SuccessPayment',
      dataLayer: {
        userId: userId,
        orderId: orderId,
        email,
        firstName,
        lastName,
      },
    }
    TagManager.initialize(tagManagerArgs)

    const conversionEventData = {
      send_to: environment.GOOGLE_TM_SUCCESS_PAYMENT_CONVERSION,
      value: totalPrice / 100,
      currency: 'USD',
      transaction_id: orderId,
    }
    ReactGA.event('conversion', conversionEventData)
  }, [])

  const navigate = useNavigate()
  const uploadIdPath = generatePath(APP_PATHS.home)
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.wrapper_payment}>
        <div className={styles.wrapper_icon}>
          <img color="white" src={PaymentLogo} alt="Payment complete" />
        </div>
        <p className={styles.payment_label}>Payment Successful!</p>
        <div className={styles.buttons}>
          <button className={styles.continue} onClick={() => navigate(uploadIdPath)}>
            Home
          </button>
        </div>
        <div className={styles.payment_body}>
          <span>
            Thank you for your order. You will shortly receive an email from our telehealth
            partners, Beluga Health. They will follow with a text message from the phone number
            you provided. Within the next 24 hours, you will receive an outcome of the clinicians
            review via SMS. If the clinician chooses to prescribe, you should expect your
            medication within 72 hours of that confirmed prescription.
          </span>
          <span>Your order ID is: {orderId}</span>
          <span>Welcome to the Maion community!</span>
          <span>
            If you have any questions or feedback, please contact us via email{' '}
            <a className={styles.link} href="mailto:help@maion.ai">
              help@maion.ai
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}
