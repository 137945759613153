import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useVerifyEmail } from 'shared/queries/user'
import styles from 'components/widgets/Order/styles.module.css'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { Box, Text, VStack } from '@chakra-ui/react'
export const ConfirmEmail = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const result = useVerifyEmail({ token })
  const { isLoading, error, isError } = result
  if (isLoading) {
    return <div>Loading</div>
  }
  if (isError) {
    return (
      <Box className={styles.wrapper}>
        <VStack className={styles.wrapper_order}>
          <Label>Email verification failed</Label>
          <Text className={styles.order_body}>{error.message}</Text>
          <Box className={styles.buttons}>
            <button
              className={styles.continue}
              onClick={() => {
                navigate(APP_PATHS.home)
              }}
            >
              Go to home
            </button>
          </Box>
        </VStack>
      </Box>
    )
  }
  return (
    <Box className={styles.wrapper}>
      <VStack maxW={'400px'} className={styles.wrapper_order}>
        <Label>Email verified</Label>
        <Text fontSize={'14px'} lineHeight={'18px'} textAlign={'left'}>
          Your email address has been verified. We’ll now ask you to sign in and verify your
          phone number.
        </Text>
        <Box className={styles.buttons}>
          <button
            className={styles.continue}
            onClick={() => {
              navigate(APP_PATHS.registration)
            }}
          >
            Proceed to Sign In
          </button>
        </Box>
        <Box fontSize={'12px'} lineHeight={'16px'} textAlign={'left'}>
          <Text as="i" fontWeight={'bold'}>
            Why do we need to verify your phone number?
          </Text>
          <Text>
            Our qualified physicians will use your phone number as the primary contact mechanism
            for engaging with you.
          </Text>
        </Box>
      </VStack>
    </Box>
  )
}
