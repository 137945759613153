export var APP_PATHS
;((APP_PATHS) => {
  APP_PATHS['home'] = '/'
  APP_PATHS['welcome'] = '/welcome' // skipSitemap
  APP_PATHS['treatmentPlans'] = '/treatments/plans'
  APP_PATHS['treatment'] = '/treatments/' // skipSitemap
  APP_PATHS['product'] = '/product/:id'
  APP_PATHS['howitWorks'] = '/howItWorks/'
  APP_PATHS['faq'] = '/faq/'
  APP_PATHS['whyMaion'] = '/whyMaion/'
  APP_PATHS['contact'] = '/contact/'
  APP_PATHS['orderPayment'] = '/payment/:id/'
  APP_PATHS['orderSubscription'] = '/subscribe/:id'
  APP_PATHS['physician'] = '/physician/:id/'
  APP_PATHS['newAppointment'] = '/newAppointment/' // skipSitemap
  APP_PATHS['myProfile'] = '/profile/' // skipSitemap
  APP_PATHS['myProfileAboutPage'] = '/profile/about-maion' // skipSitemap
  APP_PATHS['myProfileBillingPage'] = '/profile/billing' // skipSitemap
  APP_PATHS['myProfileUserPage'] = '/profile/user' // skipSitemap
  APP_PATHS['myProfileReturnsPage'] = '/profile/returns' // skipSitemap
  APP_PATHS['myProfileReferralPage'] = '/profile/referral' // skipSitemap
  APP_PATHS['myProfileMessagesPage'] = '/profile/messages' // skipSitemap
  APP_PATHS['myProfileRenewPrescription'] =
    '/profile/subscriptions/:id/renewPrescriptionRequired'
  APP_PATHS['confirmEmail'] = '/confirm-email/:token/'
  APP_PATHS['inviteByEmail'] = '/invite-email/:token/'
  APP_PATHS['basket'] = '/profile/basket' // skipSitemap
  APP_PATHS['orderUploadId'] = '/order/uploadId/:orderId'
  APP_PATHS['registration'] = '/registration/'
  APP_PATHS['registrationWithToken'] = '/registration/:token'
  APP_PATHS['registrationStep1'] = '/registration/details/' // skipSitemap
  APP_PATHS['verifyPhoneNumber'] = '/registration/verifyPhone/:token/:phoneNumber'
  APP_PATHS['finishRegistration'] = '/registration/finish/:token'
  APP_PATHS['completeRegistration'] = '/registration/complete/:token'
  APP_PATHS['schedule'] = '/schedule/' // skipSitemap
  APP_PATHS['schedulePickDate'] = '/schedule/date/' // skipSitemap
  APP_PATHS['scheduleDetails'] = '/schedule/details/' // skipSitemap
  APP_PATHS['scheduleResult'] = '/schedule/result/' // skipSitemap
  APP_PATHS['scheduleCalendar'] = '/schedule/calendar/' // skipSitemap
  APP_PATHS['schedulePayment'] = '/schedule/payment/' // skipSitemap
  APP_PATHS['referrals'] = '/referrals' // skipSitemap
  APP_PATHS['termsAndConditions'] = '/terms-and-conditions'
  APP_PATHS['privacyPolicy'] = '/privacy-policy'
  APP_PATHS['authWeb2Provider'] = '/oauth' // skipSitemap
  APP_PATHS['resetPassword'] = '/reset-password/:token'
})(APP_PATHS || (APP_PATHS = {}))
