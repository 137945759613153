import React from 'react'
import { Layout } from '../layout'
import styles from '../profile.module.css'
import { Label } from '../../../components/layout/label'
import { BillingCards } from '../components/billingCards/cards'
import { InvoiceTable } from '../components/invoiceTable/table'
import { useIndexMySubscriptions } from '../../../shared/queries/subscription'
import { useIndexMyOrders } from '../../../shared/queries/order'
import { isEmpty } from 'lodash'
import { APP_PATHS } from '../../../paths'

export const BillingPage = () => {
  const {
    data: subscriptions = [],
    isLoading: isSubscriptionsLoading,
    refetch: refetchSubscriptions,
  } = useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] })
  const subscription = subscriptions?.[0] || {}
  const { id } = subscription
  const {
    data: orders = [],
    isLoading: isOrdersLoading,
    refetch,
  } = useIndexMyOrders(
    { subscriptionIds: [id] },
    !isSubscriptionsLoading && !isEmpty(subscriptions) && !!id
  )
  if (isSubscriptionsLoading || isOrdersLoading) {
    return null
  }
  return (
    <Layout>
      {({ profile }) => {
        return (
          <div className={`${styles.page_wrapper} ${styles.billingPage}`}>
            <Label className={`${styles.mb20} ${styles.text_left}`}>
              Billing & Payment Details
            </Label>
            {isEmpty(subscription) ? (
              <div className={styles.text}>
                You don`t have any active subscription.{' '}
                <a href={APP_PATHS.treatmentPlans}>Choose plan</a>
              </div>
            ) : (
              <>
                <BillingCards
                  subscription={subscription}
                  orders={orders}
                  profile={profile}
                  refetchSubscriptions={refetchSubscriptions}
                />
                <div style={{ margin: '20px' }} />
                <InvoiceTable orders={orders} refetch={refetch} />
              </>
            )}
          </div>
        )
      }}
    </Layout>
  )
}
