import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, size } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  //ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

import { Label } from 'components/layout/label'
import RoundArrow from 'shared/icons/misc/roundArrow.svg'

import CreditCardIcon from 'shared/icons/misc/creditCard.svg'

import { CardSkeleton, Button } from 'shared/components'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import { useIndexMyPaymentMethods } from 'shared/queries/paymentMethod'
import { useCreateSubscriptionMutation } from 'shared/mutations/payment'
import { useCreatePaymentMethodMutation } from 'shared/mutations/paymentMethod'

import { PaymentComplete } from './components/complete/paymentComplete'
import { PaymentFailed } from './components/complete/paymentFailed'
import { GooglePayPayment, StripePayment } from './components/stripe/stripe'

import { environment } from 'environments'
import styles from './styles.module.css'

import { useBasketStore } from '../../shared/stores/basketStore'
import { Questionnaires } from './components/questionnaries'
import { useIndexMySubscriptions } from '../../shared/queries/subscription'
import { SubscriptionDisabled } from './components/complete/subscriptionDisabled'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react'
import { QUESTION_TYPES } from '../../shared/constants/common'
import { useUserContextState } from '../../shared/contexts/user-context-provider'
import { SubscriptionLogin } from './components/complete/subscriptionLogin'
import { useValidateCouponCode } from '../../shared/queries/coupon'
import { useAttachPhotosToOrderMutation } from 'shared/mutations/order'

const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY)

const Subscription = ({ product, totalPrice, couponCode, couponData }) => {
  const { removeItem } = useBasketStore()
  const { id: productId } = useParams()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [questionnairesResponses, setQuestionnairesResponses] = useState([])
  const [files, setFiles] = useState(undefined)
  const [isAddCard, setIsAddCard] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [selectedCard, setSelectedCard] = useState(0)
  const [backBTNActive, setBackBTNActive] = useState(false)

  const [showPaymentComplete, setShowPaymentComplete] = useState(false)
  const paymentComplete = () => {
    setShowPaymentComplete(true)
    removeItem(productId)
  }

  const [showPaymentError, setShowPaymentError] = useState(false)
  const { data: profile, isLoading: isProfileLoading } = useReadMyUserProfile()
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useIndexMyPaymentMethods({
    status: 'ACTIVE',
  })
  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethodMutation()
  const { mutateAsync: createSubscription, isLoading } = useCreateSubscriptionMutation()
  const { mutate: uploadPhotos } = useAttachPhotosToOrderMutation(orderId)

  const handleSubmit = async (values) => {
    let subscriptionData = null
    if (isAddCard) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      setProcessing(true)
      const card = elements.getElement(CardElement)

      if (card == null) {
        setProcessing(false)
        return
      }

      if (error) {
        setProcessing(false)
        card.focus()
        return
      }
      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: values.name,
          email: values.email,
        },
      })
      if (paymentError) {
        setProcessing(false)
        setError(paymentError)
        return
      }
      const { isPack = false, id, amount, subscriptionRecurringInDays } = product
      const productPayload = {
        amount,
      }
      if (isPack) {
        productPayload.packOfferId = id
      } else {
        productPayload.productId = id
      }

      const paymentParams = {
        questionnairesResponses,
        items: [productPayload],
        periodInDays: subscriptionRecurringInDays,
      }
      const confirmPaymentParams = {}
      /*
      if (values.saveData) {
        const savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
        paymentParams.paymentMethodId = savedPaymentMethod.id
      } else {
        confirmPaymentParams.payment_method = paymentMethod.id
      }
      */

      let savedPaymentMethod
      try {
        savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
      } catch (e) {
        setProcessing(false)
        setBackBTNActive(true)
        return
      }

      if (savedPaymentMethod) {
        ReactGA.event('add_payment_info')
      }
      paymentParams.paymentMethodId = savedPaymentMethod.id
      if (couponCode) paymentParams.couponCode = couponCode
      let data
      try {
        data = await createSubscription(paymentParams)
      } catch (e) {
        setError(e.message)
        setShowPaymentError(true)
        return
      }
      subscriptionData = data
      const { clientSecret, currentOrderId } = data
      setOrderId(currentOrderId)
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        confirmPaymentParams
      )

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
        removeItem(productId)
      } else {
        isPrescriptionRequired && (await uploadPhotos({ photos: files }))
        paymentComplete()
      }

      setProcessing(false)
    } else if (selectedCard > 0) {
      if (!stripe) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      setProcessing(true)

      if (error) {
        setProcessing(false)
        return
      }

      const { isPack = false, id, amount, subscriptionRecurringInDays } = product
      const productPayload = {
        amount,
      }
      if (isPack) {
        productPayload.packOfferId = id
      } else {
        productPayload.productId = id
      }

      const savedPaymentMethod = paymentMethods[selectedCard - 1]
      const paymentParams = {
        questionnairesResponses,
        items: [productPayload],
        periodInDays: subscriptionRecurringInDays,
        paymentMethodId: savedPaymentMethod.id,
      }
      if (couponCode) paymentParams.couponCode = couponCode
      let data
      try {
        data = await createSubscription(paymentParams)
      } catch (e) {
        setError(e.message)
        setShowPaymentError(true)
        return
      }
      if (isEmpty(data)) {
        setError("Couldn't create subscription")
        setShowPaymentError(true)
        return
      }
      subscriptionData = data
      const { clientSecret, currentOrderId } = data
      setOrderId(currentOrderId)
      const { error: confirmError } = await stripe.confirmCardPayment(clientSecret)

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
        removeItem(productId)
      } else {
        isPrescriptionRequired && (await uploadPhotos({ photos: files }))
        paymentComplete()
      }
    }

    if (subscriptionData) {
      const discount = couponData?.discount
        ? (product.priceInCents * couponData?.discount) / 100
        : couponData?.discountAmount || 0
      const totalAmountWithDiscount = product.priceInCents - discount
      const price = totalAmountWithDiscount / 100

      const subscriptionEventData = {
        currency: 'USD',
        value: totalPrice / 100,
        transaction_id: subscriptionData?.id,
        coupon: couponCode || null,
        order_id: subscriptionData?.currentOrderId,
        items: [
          {
            item_id: product.id,
            item_name: product.title,
            affiliation: product.medName,
            coupon: couponCode || null,
            discount,
            price,
            quantity: product.amount,
            ndc: product.ndc,
            sku: product.sku,
          },
        ],
      }
      // https://developers.google.com/tag-platform/gtagjs/reference/events#purchase
      ReactGA.event('purchase', subscriptionEventData)
    }
  }

  const handleExpress = async (event) => {
    if (!stripe) {
      return
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setError(submitError.message)
      return
    }
    const { billingDetails } = event
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails,
      },
    })

    if (error) {
      setError(error.message)
      setProcessing(false)
      return
    }
    const { isPack = false, id, amount, subscriptionRecurringInDays } = product
    const productPayload = {
      amount,
    }
    if (isPack) {
      productPayload.packOfferId = id
    } else {
      productPayload.productId = id
    }

    const confirmPaymentParams = {}
    const savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
    const paymentParams = {
      questionnairesResponses,
      items: [productPayload],
      periodInDays: subscriptionRecurringInDays,
      paymentMethodId: savedPaymentMethod.id,
    }
    if (couponCode) paymentParams.couponCode = couponCode
    let data
    try {
      data = await createSubscription(paymentParams)
    } catch (e) {
      setError(e.message)
      setShowPaymentError(true)
      return
    }
    const { clientSecret, currentOrderId } = data
    setOrderId(currentOrderId)
    const { error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      confirmPaymentParams
    )

    if (confirmError) {
      setError(confirmError.message)
      setShowPaymentError(true)
      removeItem(productId)
    } else {
      isPrescriptionRequired && (await uploadPhotos({ photos: files }))
      paymentComplete()
    }

    setProcessing(false)
  }

  const cardSchema = Yup.object({
    name: Yup.string()
      .required('Card Holder name is required')
      .max(20, 'Name cannot be longer than 20 characters'),
    email: Yup.string().email('Invalid email').required('Email Address is required'),
    phone: Yup.string(),
  })
  const formik = useFormik({
    initialValues: {
      name: profile ? `${profile.firstName} ${profile.lastName}` : '',
      email: profile?.email || '',
      saveData: false,
    },
    validationSchema: cardSchema,
    onSubmit: handleSubmit,
  })
  useEffect(() => {
    if (!isProfileLoading && !isEmpty(profile)) {
      formik.setFieldValue('name', `${profile.firstName} ${profile.lastName}`)
      formik.setFieldValue('email', profile?.email)
    }
  }, [profile, isProfileLoading])

  const { amount, isPrescriptionRequired } = product

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: environment.GOOGLE_TM_SUCCESS,
      dataLayerName: 'Subscription',
      dataLayer: {
        userId: profile.id,
        productId: productId,
      },
    }
    const conversionEventData = {
      send_to: environment.GOOGLE_TM_SUCCESS_PAYMENT_CONVERSION,
      value: totalPrice / 100,
      currency: 'USD',
      transaction_id: orderId,
    }

    if (
      (isPrescriptionRequired && !isEmpty(questionnairesResponses)) ||
      !isPrescriptionRequired
    ) {
      TagManager.initialize(tagManagerArgs)
      ReactGA.event('conversion', conversionEventData)
    }
  }, [isPrescriptionRequired, questionnairesResponses])

  if (isPaymentMethodsLoading) {
    return <div className={styles.wrapper}>Loading</div>
  }

  /*
  const { productId, amount } = items[0]
  const product = products.find(({ id }) => id === productId)
  */
  const readyToConfirm = (selectedCard === 0 && isAddCard && formik.isValid) || selectedCard > 0

  if (showPaymentComplete) {
    window.location.hash = 'SuccessPayment'
    return (
      <PaymentComplete
        orderId={orderId}
        userId={profile.id}
        profile={profile}
        totalPrice={totalPrice}
      />
    )
  }

  if (showPaymentError) {
    return <PaymentFailed message={error} />
  }

  if (isPrescriptionRequired && isEmpty(questionnairesResponses)) {
    return (
      <Questionnaires
        productId={product.id}
        type={QUESTION_TYPES.QUESTIONNAIRE}
        handleComplete={setQuestionnairesResponses}
        handleFiles={setFiles}
      />
    )
  }
  return (
    <div className={styles.wrapper}>
      <Label>Payment</Label>
      <div className={styles.payment_wrapper}>
        <div className={`${styles.payment_part_column} ${styles.payment_left_part}`}>
          {isAddCard ? (
            <StripePayment
              isLoading={isLoading}
              formik={formik}
              error={error}
              setError={setError}
            />
          ) : (
            <Accordion defaultIndex={[0]} allowMultiple w="100%">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <img src={CreditCardIcon} alt="Credit card icon" />
                    <Box as="span" flex="1" textAlign="left" ml={2}>
                      Credit card
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div className={styles.credit_card_block_card_wrapper}>
                    <div className={styles.card_wrapper}>
                      <div className={styles.card}>
                        {selectedCard === 0 ? (
                          <CardSkeleton totalPriceInCents={totalPrice} />
                        ) : (
                          <CardSkeleton
                            totalPriceInCents={totalPrice}
                            mask={paymentMethods[selectedCard - 1]?.cardNumberMask}
                            saved={true}
                            brand={paymentMethods[selectedCard - 1]?.brand}
                            expMonth={paymentMethods[selectedCard - 1]?.expMonth}
                            expYear={paymentMethods[selectedCard - 1]?.expYear}
                          />
                        )}
                      </div>
                      <div
                        className={styles.card_back}
                        style={{
                          background: 'var(--gray-text-secondary)',
                          right: '-10px',
                          height: '86%',
                          zIndex: 3,
                        }}
                      />
                      <div
                        className={styles.card_back}
                        style={{
                          background: 'var(--secondary-bg)',
                          right: '-20px',
                          height: '70%',
                          zIndex: 2,
                        }}
                      />
                    </div>

                    <div className={styles.switch_method}>
                      {selectedCard > 0 && (
                        <img
                          className={styles.prev_button}
                          src={RoundArrow}
                          alt={'Prev'}
                          onClick={() => setSelectedCard((prevState) => prevState - 1)}
                        />
                      )}
                      Switch Payment Method
                      {size(paymentMethods) > selectedCard && (
                        <img
                          src={RoundArrow}
                          alt={'Next'}
                          onClick={() => setSelectedCard((prevState) => prevState + 1)}
                        />
                      )}
                    </div>
                    {selectedCard === 0 && (
                      <Button
                        onClick={() => {
                          setIsAddCard(true)
                          setBackBTNActive(true)
                        }}
                      >
                        Add Card
                      </Button>
                    )}
                  </div>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <Box m={4}>
                  <GooglePayPayment
                    stripe={stripe}
                    amount={totalPrice}
                    onConfirm={handleExpress}
                  />
                </Box>
              </AccordionItem>
            </Accordion>
          )}
        </div>
        <div className={styles.payment_part_column}>
          <div className={styles.pencil_wrapper}>
            <img src={product.imageUrl} alt={'_'} />
          </div>
          <div className={styles.payment_item_text}>
            <p>{product.title}</p>
            <span>Quantity: {amount}</span>
          </div>
          <div className={styles.payment_disclaimer}>
            If you have accurately completed the form to the best of your knowledge, it is highly
            likely that your medication will be approved. However, if our clinician doesn&apos;t
            issue a prescription, you will be charged a $25 consultation fee. You will of course
            be able to <a href={'mailto:support@maion.ai'}>contact us</a> if you believe there
            has been an error in this evaluation.
          </div>
          <div className={styles.buttons}>
            <button
              className={backBTNActive ? styles.continue : styles.back}
              onClick={() => {
                setIsAddCard(false)
                setProcessing(false)
                setBackBTNActive(false)
              }}
            >
              Back
            </button>
            <button
              disabled={!!error || !readyToConfirm || processing}
              className={styles.continue}
              onClick={() => handleSubmit(formik.values)}
            >
              {processing ? 'Processing' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SubscriptionPage = () => {
  const { user, isUserLoading } = useUserContextState()

  const { data: subscriptions = [], isLoading: isSubscriptionsLoading } =
    useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] })
  const { getItem } = useBasketStore()
  const { id: productId } = useParams()
  const [params] = useSearchParams()
  const couponCode = params.get('couponCode')
  const product = useMemo(() => getItem(productId), [productId])
  const { data: couponData, isLoading: isCouponLoading } = useValidateCouponCode(
    { code: couponCode, productIds: [product.id] },
    !!couponCode
  )
  if (!isUserLoading && isEmpty(user)) {
    return <SubscriptionLogin />
  }

  if (isSubscriptionsLoading || isUserLoading || isCouponLoading) {
    return null
  }

  if (!isEmpty(subscriptions)) {
    window.location.hash = 'SubscriptionDisabled'
    return <SubscriptionDisabled />
  }

  let { totalPrice } = product

  if (!isEmpty(couponData)) {
    const { discount, discountAmount: discountAmountInCents } = couponData
    const discountAmount = discount ? (totalPrice * discount) / 100 : discountAmountInCents
    totalPrice = totalPrice - discountAmount
  }
  const options = {
    mode: 'subscription',
    amount: totalPrice,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    appearance: {
      variables: {
        borderRadius: '4px',
      },
    },
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <Subscription
        product={product}
        totalPrice={totalPrice}
        couponCode={couponCode}
        couponData={couponData}
      />
    </Elements>
  )
}
