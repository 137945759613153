import React, { useState } from 'react'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useFormik } from 'formik'
import { APP_PATHS } from 'paths'
import { isEmpty, trimStart } from 'lodash'
import { useToast } from '@chakra-ui/react'
import moment from 'moment'
import * as Yup from 'yup'
import 'yup-phone'

import { Label } from 'components/layout/label'
import { useSetUserProfileMutation } from 'shared/mutations/profile'
import { sleep } from 'shared/utils/sleep'

import { FirstStep } from './steps/FirstStep'
import { AddressStep } from './steps/AddressStep'
import { LastStep } from './steps/LastStep'
import styles from './detailPage.module.css'

function fullBirthDate({ month, day, year }) {
  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num)
  const birthDate = `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}T00:00:00.000Z`

  return birthDate
}

function isValidBirthdayDate() {
  const { month, day, year } = this.parent

  if (!month || !day || !year) {
    return false
  }

  return moment().diff(fullBirthDate({ month, day, year }), 'years') >= 18
}

const getValidationSchema = (step) => {
  switch (step) {
    case 0:
      return Yup.object({
        firstName: Yup.string().required('Legal First Name is required'),
        lastName: Yup.string().required('Legal Last Name is required'),
        birthDate: Yup.date().when(['month', 'day', 'year'], {
          is: (month, day, year) => month && day && year,
          then: (schema) =>
            schema.test('is-correct-date', 'You must be 18-years old', isValidBirthdayDate),
          otherwise: (schema) => schema.required('Date of Birth is required'),
        }),
        phoneNumber: Yup.string()
          .phone('US', true, 'Invalid Phone Number')
          .required('Phone Number is required'),
      })
    case 1:
      return Yup.object({
        address: Yup.object({
          addressLocality: Yup.string().required('City is required'),
          addressRegion: Yup.string().required('State is required'),
          postalCode: Yup.string()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits')
            .required('Zip is required'),
          streetAddress: Yup.string().required('Street Address is required'),
        }),
      })
    case 2:
      return Yup.object({
        gender: Yup.string().required('Sex Assigned At Birth is required'),
        initialWeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Initial Weight is required'),
        targetWeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Target Weight is required'),
        initialHeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Initial Height is required'),
        acceptedTermsVersion: Yup.boolean().oneOf(
          [true],
          'You must agree to the terms and conditions'
        ),
        prefGenderPronouns: Yup.string(),
        isNewslettersOn: Yup.boolean(),
      })
    default:
      return Yup.object()
  }
}

export const FinishRegistrationPage = () => {
  const navigate = useNavigate()
  const queryCache = useQueryClient()
  const { token } = useParams()
  const toast = useToast()
  const [params] = useSearchParams()
  const referralToken = params.get('referralToken')

  const [fieldErrors, setFieldErrors] = useState({})

  const { mutate: onSetProfile } = useSetUserProfileMutation({
    onSuccess: async ({ token, isPhoneNumberVerificationRequired, phoneNumber }) => {
      if (phoneNumber && isPhoneNumberVerificationRequired) {
        await navigate(generatePath(APP_PATHS.verifyPhoneNumber, { token, phoneNumber }))
        return
      }
      localStorage.setItem('AUTH_TOKEN', token)
      await queryCache.refetchQueries()
      await sleep(1000)
      await navigate(APP_PATHS.myProfile)
    },
    onError: ({ error }) => {
      const { code, fields, message, context } = error
      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      } else if (code === 'MNC-1-1') {
        let errors = {}
        if (context?.phoneNumber) {
          errors.phoneNumber = 'User with such phone number already exists.'
        }
        if (context?.email) {
          errors.email = 'User with such email already exists.'
        }
        setFieldErrors(errors)
      } else if (code === 'MNC-12') {
        setFieldErrors({
          birthDate: message,
        })
      }
      setStep(0)
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  const [step, setStep] = useState(0)
  const formik = useFormik({
    initialValues: {
      token,
      referralToken,
      phoneNumber: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      month: '',
      day: '',
      year: '',
      acceptedTermsVersion: 0,
      address: {
        addressCountry: 'US',
        addressLocality: '',
        addressRegion: '',
        postalCode: '',
        streetAddress: '',
      },
      gender: '',
      prefGenderPronouns: '',
      isNewslettersOn: false,
    },
    validationSchema: getValidationSchema(step),
    onSubmit: (values) => {
      if (step === 0) {
        if (values.phoneNumber) {
          values.phoneNumber = `+1${values.phoneNumber.replace(/\s+/g, '')}`
        }
      }
      if (step === 2) {
        const { month, day, year } = values

        values.birthDate = fullBirthDate({ month, day, year })

        if (values.address.postalCode) {
          values.address.postalCode = String(values.address.postalCode)
        }
        if (!values.referralToken) {
          delete values.referralToken
        }
        onSetProfile(values)
      } else {
        setStep((prev) => prev + 1)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <Label>Your Details</Label>
      <div className={styles.form}>
        {step === 0 && <FirstStep formik={formik} fieldErrors={fieldErrors} />}
        {step === 1 && <AddressStep formik={formik} fieldErrors={fieldErrors} />}
        {step === 2 && <LastStep formik={formik} fieldErrors={fieldErrors} />}
      </div>

      <div className={styles.buttons}>
        <button
          type="button"
          disabled={step === 0}
          className={styles.back}
          onClick={() => setStep((prev) => prev - 1)}
        >
          Back
        </button>
        <button type="submit" className={styles.continue} disabled={!isEmpty(formik.errors)}>
          {step === 2 ? 'Commit' : 'Confirm'}
        </button>
      </div>
    </form>
  )
}
