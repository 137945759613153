import { FormikInput } from '../../../components/elements/input'
import PropTypes from 'prop-types'
import React from 'react'
import { FormikSelectDateOfBirthday } from 'components/elements/select/date'

export const FirstStep = ({ formik, fieldErrors }) => (
  <>
    <FormikInput
      name="firstName"
      label="Legal First Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <FormikInput
      name="lastName"
      label="Legal Last Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <FormikSelectDateOfBirthday
      name="birthDate"
      label="Date of Birth"
      required
      formik={formik}
      fieldErrors={fieldErrors}
    />
    <FormikInput
      name="phoneNumber"
      label="Phone Number"
      required
      type="tel"
      placeholder={'xxx xxx xxxx'}
      note="Standard 10-digit US telephone number format. Please don't include '-' or '+1.'"
      formik={formik}
      errors={fieldErrors}
    />
  </>
)

FirstStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
