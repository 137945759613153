// import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useIndexUsers = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.user, params], () => axios.get('/indexUsers', { params }), {
    retry: false,
    enabled,
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })
}

export const useReadMyUser = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.user, params], () => axios.get('/readMyUser', { params }), {
    retry: false,
    enabled,
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })
}

export const useVerifyEmail = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.emailVerification, params],
    () => axios.get('/verifyEmail', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        console.log(error)
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}
