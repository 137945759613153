import React from 'react'
import { VStack } from '@chakra-ui/react'
import { Label } from 'components/layout/label'
import ContactsPageImage from 'shared/images/contacts-page.png'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { HumanSvg } from 'shared/icons/misc/human'
import { APP_PATHS } from 'paths'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'
import { TreatmentProductItemsItems } from '../treatments/TreatmentPlans'
import { DoctorsList } from 'features/treatments/doctorsList'

export const ContactsPage = () => {
  const { user } = useUserContextState()
  return (
    <div>
      <VStack
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="10px"
        mb="100px"
        p="7% 8% 0 8%"
      >
        <Label
          subTitle={
            <div className={styles.second_label}>
              <span>How can we help? Our team of healthcare</span>
              <span>professionals are here to help you</span>
            </div>
          }
        >
          Contact Maion
        </Label>

        <div className={styles.items_wrapper}>
          <div className={styles.left}>
            <div className={styles.text}>
              <span>Email:</span> <Link to={'mailto:hello@maion.ai'}>hello@maion.ai</Link>
              {isEmpty(user) && (
                <div className={styles.login_wrapper}>
                  <span>Or login: </span>
                  <Link className={styles.button} to={APP_PATHS.registration}>
                    <HumanSvg width={'17'} height={'14'} />
                    <span>Login</span>
                  </Link>
                </div>
              )}
            </div>
            <div />
          </div>
          <div className={styles.right}>
            <img
              src={ContactsPageImage}
              alt={'Phone with call to doctor'}
              className={styles.image}
            />
          </div>
        </div>
      </VStack>
      <DoctorsList clear={false} className={styles.doctors} />
      <TreatmentProductItemsItems style={{ paddingTop: '50px' }} />
    </div>
  )
}
