// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useInviteUserMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/inviteUser', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useRegisterUserMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/registerUser', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useRegisterConsumerUserMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/registerConsumer', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useRequestResetPasswordMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/requestResetPassword', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useResetPasswordMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/resetPassword', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useChangePasswordMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/updatePassword', data), {
    onSuccess: () => {
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Password successfully changed',
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useResendVerificationEmailMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/resendVerificationEmail', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    onSuccess: () => {
      toast({
        position: 'top-right',
        status: 'success',
        title: 'The mail has been successfully sent to your email!',
        isClosable: true,
      })
    },
    ...options,
  })
}

export const useResendVerificationSmsMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/resendVerificationSms', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCreateSessionByEmailMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/createSessionByEmail', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCreateSessionByAttemptTokenMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/createSessionByAttemptToken', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCreateSessionByOauthCodeMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/createSessionByOauthCode', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useDeleteSessionMutation = (options) => {
  const toast = useToast()
  return useMutation(async () => axios.delete('/deleteMySession '), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useVerifyPhoneNumberMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/verifyPhoneNumber', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
