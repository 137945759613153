import React from 'react'

import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { APP_PATHS } from 'paths'

import { useUserContextState } from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'

const Item = () => (
  <div>
    <p>JOIN THE MAION COMMUNITY AND GET OUR NEW STARTERS DISCOUNT</p>
    <Link to={APP_PATHS.registrationStep1}>Sign up</Link>
  </div>
)

export const Banner = () => {
  const { user } = useUserContextState()
  return isEmpty(user) ? (
    <div className={styles.wrapper}>
      <div className={styles.inner} id="banner">
        {new Array(100).fill(undefined).map((_, index) => (
          <Item key={index} />
        ))}
      </div>
    </div>
  ) : null
}
