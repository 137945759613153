import React from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { useAskBotStore } from '../../shared/stores/askBotStore'
import { Link } from 'react-router-dom'
import { TreatmentProductItemsItems } from '../treatments/TreatmentPlans'
import { AskBot } from 'components/elements/askBot'

const questions = [
  'Can you split your dose and take it bi weekly?',
  'Can I take Sema after having surgery (gastric sleeve surgery and gastric belt surgery)?',
  'I started at .25, now I’m at .75 and I just don’t feel much of a effect at all positive or negative.',
  'Is protein essential for weight loss or just muscle loss prevention?',
  'Why does nausea always come on when you’re trying to sleep?',
  'Can I take promethazine or zofran as well as Semaglutide?',
]

export const Page = () => {
  const { setCurrentQuestion } = useAskBotStore()
  const onQuestionClick = (text) => {
    return () => setCurrentQuestion(text)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.paddings} style={{ paddingBottom: '5px' }}>
        <Label
          subTitle={
            <div className={styles.additional_lable}>
              <span>For any other questions, please try our Maion AI</span>
              <span>
                or email us at <Link to={'mailto:chat@maion.ai'}>chat@maion.ai</Link>
              </span>
            </div>
          }
        >
          FAQ
        </Label>
        <ul className={styles.text}>
          {questions.map((question) => (
            <li onClick={onQuestionClick(question)} key={question}>
              <LiMarker />
              {question}
            </li>
          ))}
          <li className={styles.bottom_text}>Ask Maion if you want to know anything else...</li>
        </ul>
      </div>
      <AskBot />
      <TreatmentProductItemsItems style={{ paddingTop: '45px' }} />
    </div>
  )
}

const LiMarker = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="5" stroke="currentColor" strokeWidth="2" />
  </svg>
)
