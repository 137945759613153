import React, { useMemo, useState } from 'react'
import { isEmpty, lowerCase, startCase } from 'lodash'
import { CSVLink } from 'react-csv'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Badge, Box, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { USDollar } from 'shared/utils/currenciesFormatters'
import { ORDER_STATUSES } from 'shared/constants/common'
import { useReadMyPayment } from 'shared/queries/payment'
import { useSyncFirstFailedPaymentStatusMutation } from 'shared/mutations/payment'

import styles from './invoiceTable.module.css'
import Arrow from 'shared/icons/misc/arrowTable.svg'
import { environment } from 'environments'
import { generatePath, useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../../../paths'

const statusStylesMap = {
  paid: 'green',
  [ORDER_STATUSES.PRESCRIPTION_ISSUED]: 'green',
  [ORDER_STATUSES.IN_DELIVERY]: 'green',
  [ORDER_STATUSES.COMPLETE]: 'green',
  [ORDER_STATUSES.SHIPPED]: 'green',
  [ORDER_STATUSES.CANCELLED]: 'red',
  [ORDER_STATUSES.ERROR]: 'red',
  [ORDER_STATUSES.PRESCRIPTION_DECLINED]: 'red',
  [ORDER_STATUSES.PAYMENT_FAILED]: 'red',
  [ORDER_STATUSES.PENDING]: 'yellow',
  [ORDER_STATUSES.PAYMENT_ACTION_REQUIRED]: 'yellow',
  [ORDER_STATUSES.PENDING_QUESTIONNAIRE_UPDATE]: 'yellow',
  [ORDER_STATUSES.PRESCRIPTION_REQUESTED]: 'yellow',
  [ORDER_STATUSES.PROCESSING]: 'yellow',
}

const csvOrderHeaders = [
  { label: 'Invoice Id', key: 'invoiceId' },
  { label: 'Billing Date', key: 'billingDate' },
  { label: 'Plan', key: 'plan' },
  { label: 'Cost', key: 'cost' },
  { label: 'Status', key: 'statusText' },
]
const columnHelper = createColumnHelper()
const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY)
const PaymentStatus = ({
  invoiceId,
  isPhotoSent,
  isPaid,
  status,
  paymentId,
  refetch,
  ordersCount,
}) => {
  const { data: payment = [], isLoading: isPaymentLoading } = useReadMyPayment({ id: paymentId })
  const { mutateAsync: SyncFirstFailedPaymentStatus } = useSyncFirstFailedPaymentStatusMutation()
  const toast = useToast()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const handleConfirmPayment = async () => {
    setIsLoading(true)
    const stripe = await stripePromise
    const { clientSecret } = payment
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      {}
    )
    if (confirmError) {
      toast({
        position: 'top-right',
        status: 'error',
        title: confirmError.message,
        isClosable: true,
      })
    }
    if (paymentIntent?.status === 'succeeded' && ordersCount === 1) {
      await SyncFirstFailedPaymentStatus({ paymentId })
    }
    setIsLoading(false)
    refetch()
  }

  const paymentStatus = isPaid
    ? 'Paid'
    : status === ORDER_STATUSES.PENDING
      ? 'Pending'
      : 'Failed'
  const isPaymentActionRequired = status === ORDER_STATUSES.PAYMENT_ACTION_REQUIRED
  const isPaymentFailed = status === ORDER_STATUSES.PAYMENT_FAILED
  if (isPaymentLoading || isLoading) {
    return (
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    )
  }
  if (!isPaid && (isPaymentActionRequired || isPaymentFailed)) {
    return (
      <Tooltip
        label={
          isPaymentFailed
            ? 'Please check or change your Payment Method.'
            : 'Please complete payment.'
        }
        placement="bottom-end"
        hasArrow
        px={2}
        py={1}
        bg={'var(--secondary-gray)'}
        rounded={8}
        fontSize={'lg'}
        fontWeight="medium"
      >
        <button className={styles.download_button} onClick={handleConfirmPayment}>
          Complete
        </button>
      </Tooltip>
    )
  }
  if (!isPhotoSent) {
    const uploadIdPath = generatePath(APP_PATHS.orderUploadId, {
      orderId: invoiceId,
    })
    return (
      <button className={styles.download_button} onClick={() => navigate(uploadIdPath)}>
        Upload ID
      </button>
    )
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <Badge
        colorScheme={isPaid ? 'green' : ORDER_STATUSES.PENDING === status ? 'yellow' : 'red'}
        p="4px 11px"
        borderRadius="15px"
      >
        <Text display="flex" fontSize="14px" w="fit-content">
          {paymentStatus}
        </Text>
      </Badge>
    </Box>
  )
}

const columns = [
  columnHelper.accessor('invoiceId', {
    cell: (info) => `#${info.getValue().split('', 8).join('')}...`,
    header: () => (
      <span>
        Invoice ID <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('billingDate', {
    cell: (info) => info.getValue(),
    header: () => (
      <span>
        Billing Date <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('plan', {
    header: () => (
      <span>
        Plan <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('cost', {
    header: () => (
      <span>
        Amount <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('status', {
    header: () => (
      <span>
        Status <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    cell: (info) => {
      const color = statusStylesMap[info.getValue()] ?? statusStylesMap.pending
      return (
        <Box display="flex" alignItems="center" justifyContent="flex-start" mr={1}>
          <Badge colorScheme={color} p="4px 11px" borderRadius="15px">
            <Text display="flex" fontSize="14px" w="fit-content" whiteSpace="normal">
              {startCase(lowerCase(info.getValue()))}
            </Text>
          </Badge>
        </Box>
      )
    },
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('isPaid', {
    header: () => (
      <span>
        Payment Status <img src={Arrow} alt={'Arrow Down'} />
      </span>
    ),
    cell: (info) => <PaymentStatus {...info.row.original} />,
    footer: (info) => info.column.id,
  }),
]

export const InvoiceTable = ({ orders, refetch }) => {
  //const [data, setData] = React.useState(() => [...defaultData])
  const data = useMemo(() => {
    if (isEmpty(orders)) {
      return []
    }
    return orders.map(
      ({ id, isPaid, paymentId, updatedAt, status, totalPriceInCents, isPhotoSent }, index) => {
        return {
          invoiceId: id,
          paymentId,
          billingDate: new Date(updatedAt).getFullYear(),
          plan: `Month ${(index % 12) + 1} of 12`,
          cost: USDollar.format(totalPriceInCents / 100),
          status: status,
          statusText: startCase(lowerCase(status)),
          isPaid,
          isPhotoSent,
          refetch,
          ordersCount: orders.length,
        }
      }
    )
  }, [orders])
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  return (
    <section style={{ width: '100%' }}>
      <div className={styles.top_wrapper}>
        <div className={styles.text_wrapper}>
          <p className={styles.label}>Invoice</p>
          <span className={styles.description}>
            Effortlessly handle your billing and invoices right here.
          </span>
        </div>
        <CSVLink data={data} headers={csvOrderHeaders} filename={`Subscription details.csv`}>
          <button className={styles.download_button}>Download</button>
        </CSVLink>
      </div>
      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={styles.table_row}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}
