import React from 'react'
import PropTypes from 'prop-types'

import { FormikSelectDateOfBirthday } from 'components/elements/select/date'
import { FormikInput, PasswordInput } from '../../../components/elements/input'
import { Checkbox } from '../../../components/elements/checkbox'
import { Link } from 'react-router-dom'
import { APP_PATHS } from 'paths'

export const InitialStep = ({ formik, fieldErrors }) => (
  <>
    <FormikInput
      name="firstName"
      label="Legal First Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <FormikInput
      name="lastName"
      label="Legal Last Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <FormikInput
      name="email"
      label="Email Address"
      required
      type="email"
      formik={formik}
      errors={fieldErrors}
    />
    <PasswordInput
      name="password"
      label="Password"
      required
      formik={formik}
      errors={fieldErrors}
    />
    <FormikSelectDateOfBirthday
      name="birthDate"
      label="Date of Birth"
      formik={formik}
      fieldErrors={fieldErrors}
      required
    />
    <FormikInput
      name="phoneNumber"
      label="Phone Number"
      note="Standard 10-digit US telephone number format. Please don't include '-' or '+1.'"
      required
      type="tel"
      formik={formik}
      errors={fieldErrors}
      placeholder={'xxx xxx xxxx'}
    />
    <Checkbox
      name="acceptedTermsVersion"
      label={
        <div>
          I agree to the{' '}
          <Link
            to={APP_PATHS.termsAndConditions}
            target="__blank"
            style={{ textDecoration: 'underline' }}
          >
            Terms and Conditions
          </Link>
          .
        </div>
      }
      required
      checked={formik.values.acceptedTermsVersion}
      onChange={(value) => formik.setFieldValue('acceptedTermsVersion', +value)}
    />
  </>
)

InitialStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
