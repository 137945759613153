import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
const moment = require('moment')
import {
  // Avatar, // For future
  Box,
  Button,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
  useToast,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react'

import { WistiaPlayer, WistiaProvider } from '@wistia/react-embeds'
import { PiPlayCircleThin } from 'react-icons/pi'
import { FaFacebook, FaCopy } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

import { APP_PATHS } from 'paths'
import { environment } from '../../../../environments'

import styles from './styles.module.css'
import ArrowSlider from 'shared/icons/misc/arrowSlider.svg'
import Share from 'shared/icons/share.svg'

import { useMediaQuery } from 'shared/hooks/useMediaQuery'

import { useIndexVideoBlocks } from 'shared/queries/videoBlocks'

const getTimeDifference = (startDate) => {
  const start = moment(startDate * 1000).startOf('day')
  const end = moment().startOf('day')

  const days = end.diff(start, 'days')
  const weeks = end.diff(start, 'weeks')

  if (days === 0) {
    return 'today'
  } else if (weeks >= 1) {
    return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`
  } else {
    return days === 1 ? '1 day ago' : `${days} days ago`
  }
}

const videoCodesParse = (wistiaVideoURL) => {
  const videoList = wistiaVideoURL[0].videos

  return videoList.map((item) => {
    const urlParts = item.url.split('/')
    const code = urlParts[urlParts.length - 1]
    return code
  })
}

const handleShare = ({ platform, title, code, toast }) => {
  const shareText = `Maion - ${title}.`
  const shareUrl = `${environment.SELF_URL}/howItWorks/?wvideo=${code}`
  const fullShareContent = `${shareText} ${shareUrl}`

  const openPopup = (url) => {
    const width = 550
    const height = 400
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2
    const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,status=yes`
    window.open(url, 'sharePopup', features)
  }

  switch (platform) {
    case 'twitter':
      openPopup(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          shareText
        )}&url=${encodeURIComponent(shareUrl)}`
      )
      break
    case 'facebook':
      openPopup(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`)
      break
    case 'copy':
      navigator.clipboard
        .writeText(fullShareContent)
        .then(() => {
          toast({
            title: 'Content copied to clipboard!',
            status: 'success',
            position: 'top-right',
            isClosable: true,
          })
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
        })
      break
    default:
      return
  }
}

const ArrowPrev = (
  <Image
    src={ArrowSlider}
    alt={'Arrow prev'}
    boxSize="24px"
    style={{ marginLeft: '-30px', marginTop: 'calc(5rem + 10%)', alignSelf: 'flex-start' }}
  />
)

const ArrowNext = (
  <Image
    src={ArrowSlider}
    alt={'Arrow next'}
    boxSize="24px"
    style={{
      marginRight: '-30px',
      marginTop: 'calc(5rem + 10%)',
      alignSelf: 'flex-start',
      transform: 'scaleX(-1)',
    }}
  />
)

const indicators = () => <div className="indicator" />

const VideoCard = ({ code }) => {
  const toast = useToast()

  const playerRef = useRef(null)
  const [wistiaContextMedia, setWistiaContextMedia] = useState({})
  const [isVideoLoading, setIsVideoLoading] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isDescriptionLines, setDescriptionLines] = useState(3)
  const [searchParams, set] = useSearchParams()
  const wvideo = searchParams.get('wvideo')

  const [videoCode] = useState(wvideo)

  const title = wistiaContextMedia?.name || ''
  const description = wistiaContextMedia?.seoDescription || ''
  const createdAt = wistiaContextMedia?.createdAt || ''
  const duration = wistiaContextMedia?.duration || ''

  const durationMinutes = Math.floor(duration / 60)

  useEffect(() => {
    set(undefined)
  }, [wvideo])

  const handlePlayPause = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pause()
      } else {
        playerRef.current.popover.showAndPlay()
      }
      setIsPlaying(!isPlaying)
    }
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    if (videoCode === code) {
      playerRef.current.popover.showAndPlay()
    }
    setIsVideoLoading(false)
  }

  const showHideText = () => {
    if (isDescriptionLines === null) {
      setDescriptionLines(3)
    } else {
      setDescriptionLines(null)
    }
  }

  const isShowMoreBtn = description.length > 80 ? true : false

  return (
    <Box
      maxW="450px"
      w="100%"
      borderRadius="2xl"
      backgroundColor="#241426"
      overflow="hidden"
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <Box w="100%" h="250px" position="relative">
        {isVideoLoading && (
          <Skeleton height="100%" width="100%" position="absolute" top="0" left="0" zIndex="1" />
        )}
        <WistiaPlayer
          hashedId={code}
          videoFoam={false}
          popover={true}
          seo={true}
          customOptions={{
            playButton: false,
          }}
          onReady={(data) => {
            handlePlayerReady(data)
          }}
          onHasData={(data) => {
            setWistiaContextMedia(data.data.media)
            setIsVideoLoading(false)
          }}
        />

        <Icon
          as={PiPlayCircleThin}
          boxSize="75px"
          color="white"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          onClick={handlePlayPause}
          cursor="pointer"
        />
        {duration && (
          <Box
            w="60px"
            h="26px"
            borderRadius="md"
            backgroundColor="#24273080"
            position="absolute"
            top="15px"
            right="15px"
          >
            <Text color="white" opacity="inherit">
              {durationMinutes} min
            </Text>
          </Box>
        )}
        {/* For the future */}
        {/* <Box
          boxSize="68px"
          borderRadius="full"
          border="1px"
          borderColor="#FFFFFF80"
          position="absolute"
          bottom="-34px"
          right="26px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar boxSize="56px" />
        </Box> */}
      </Box>
      {description ? (
        <>
          <VStack alignItems="flex-start" px={7} py={5} flex={1}>
            <Text
              color="#B7B9D2"
              fontWeight="medium"
              fontSize="17px"
              lineHeight="15px"
              minH="30px"
              maxW="250px"
              textAlign="start"
              whiteSpace="break-spaces"
              // noOfLines={2}
            >
              {title}
            </Text>
            <Text
              color="white"
              fontWeight="semibold"
              fontSize="20px"
              lineHeight="28px"
              textAlign="start"
              whiteSpace="break-spaces"
              noOfLines={isDescriptionLines}
            >
              {description}
            </Text>
            {isShowMoreBtn && (
              <Text color="#D537A8" onClick={() => showHideText()} cursor="pointer">
                {isDescriptionLines === null ? 'Show less' : 'Show more'}
              </Text>
            )}
            <Spacer />
            {createdAt && (
              <Text
                color="#808191"
                fontWeight="medium"
                fontSize="15px"
                lineHeight="13px"
                textAlign="start"
              >
                Maion • {getTimeDifference(createdAt)}
              </Text>
            )}
          </VStack>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Share options"
              icon={<Image src={Share} alt="Share" boxSize="15px" />}
              variant="unstyled"
              size="sm"
              rounded="full"
              backgroundColor="white"
              position="absolute"
              bottom="15px"
              right="15px"
              boxSize="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            />
            <MenuList>
              <MenuItem
                onClick={() => handleShare({ platform: 'twitter', title, code, toast })}
                _hover={{ color: '#D537A8', backgroundColor: '#f0f0f0' }}
              >
                <Icon as={FaXTwitter} mr={2} />
                Share on X (Twitter)
              </MenuItem>
              <MenuItem
                onClick={() => handleShare({ platform: 'facebook', title, code, toast })}
                _hover={{ color: '#D537A8', backgroundColor: '#f0f0f0' }}
              >
                <Icon as={FaFacebook} mr={2} />
                Share on Facebook
              </MenuItem>
              <MenuItem
                onClick={() => handleShare({ platform: 'copy', title, code, toast })}
                _hover={{ color: '#D537A8', backgroundColor: '#f0f0f0' }}
              >
                <Icon as={FaCopy} mr={2} />
                Copy URL
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      ) : (
        <VStack alignItems="flex-start" px={7} py={5} flex={1} gap={2}>
          <SkeletonText noOfLines={2} width="80%" spacing="2" skeletonHeight="3" />
          <SkeletonText noOfLines={3} width="100%" spacing="3" skeletonHeight="4" />
          <Skeleton width="30%" height={3} />
        </VStack>
      )}
    </Box>
  )
}

export const WistiaVideoSlider = () => {
  const toast = useToast()

  const urlParams = new URLSearchParams(window.location.search)
  const wvideo = urlParams.get('wvideo')

  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useMediaQuery('(max-width: 800px)')
  const isSmall = useMediaQuery('(max-width: 1024px)')

  const { data: videoBlocks = [{ videos: [] }], isLoading: isVideoBlocksLoading } =
    useIndexVideoBlocks()

  const videoCodes = videoCodesParse(videoBlocks)

  const onStartChange = useCallback(
    (from, to) => {
      typeof activeIndex === 'number' && setActiveIndex(to)
    },
    [videoCodes]
  )

  if (isVideoBlocksLoading) {
    return null
  }

  const getSlidesToShow = () => {
    if (videoCodes.length <= 2 && !isMobile) return videoCodes.length
    if (isMobile) return 1
    if (isSmall) return 2
    return 3
  }

  const getSlidesToScroll = () => {
    if (isMobile || videoCodes.length < 2) return 1
    return 2
  }

  if (!videoCodes.includes(wvideo) && wvideo) {
    toast({
      title: 'Video not exist!',
      status: 'error',
      position: 'top-right',
      isClosable: true,
    })
  }

  return (
    <Box className={`${styles.wrapper}`} gap="30px" id={'some-helpful-tips'}>
      <Box w="100%">
        <Text className={styles.label}>Some helpful tips</Text>
      </Box>
      <Box className="slide-container" w="100%">
        <WistiaProvider>
          <Slide
            autoplay={videoCodes.length > (isMobile ? 1 : 3)}
            infinite={true}
            canSwipe={true}
            arrows={videoCodes.length > (isMobile ? 1 : 3)}
            prevArrow={ArrowPrev}
            nextArrow={ArrowNext}
            indicators={videoCodes.length > 3 ? indicators : null}
            pauseOnHover
            slidesToScroll={getSlidesToScroll()}
            slidesToShow={getSlidesToShow()}
            onStartChange={onStartChange}
            easing={'ease'}
          >
            {videoCodes?.map((code) => (
              <Box key={code} px={2.5} display="flex" h="100%" justifyContent="center">
                <VideoCard code={code} />
              </Box>
            ))}
          </Slide>
        </WistiaProvider>
        <VStack w="100%" alignItems="end" gap="15px" mt={3}>
          <Text fontWeight="bold" fontSize="15px" lineHeight="13px" textAlign="start">
            Choose a plan and start your weight loss journey today.
          </Text>
          <Button
            as={Link}
            to={APP_PATHS.treatmentPlans}
            colorScheme="pink"
            bgColor="#D537A8"
            justifySelf="end"
            alignSelf="end"
            size="sm"
          >
            Choose Plan
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}
