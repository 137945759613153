export var QueriesKeysEnum
;((QueriesKeysEnum) => {
  QueriesKeysEnum['user'] = 'user'
  QueriesKeysEnum['emailVerification'] = 'emailVerification'
  QueriesKeysEnum['phoneVerification'] = 'phoneVerification'
  QueriesKeysEnum['userProfile'] = 'userProfile'
  QueriesKeysEnum['userMetric'] = 'userMetric'
  QueriesKeysEnum['userLog'] = 'userLog'
  QueriesKeysEnum['paymentMethod'] = 'paymentMethod'
  QueriesKeysEnum['coupons'] = 'coupons'
  QueriesKeysEnum['injection'] = 'injection'
  QueriesKeysEnum['friendRequest'] = 'friendRequest'
  QueriesKeysEnum['message'] = 'message'
  QueriesKeysEnum['order'] = 'order'
  QueriesKeysEnum['product'] = 'product'
  QueriesKeysEnum['scheduledJob'] = 'scheduledJob'
  QueriesKeysEnum['payment'] = 'payment'
  QueriesKeysEnum['appointment'] = 'appointment'
  QueriesKeysEnum['subscription'] = 'subscription'
  QueriesKeysEnum['packOffer'] = 'packOffer'
  QueriesKeysEnum['knowledgeDocument'] = 'knowledgeDocument'
  QueriesKeysEnum['questionnaires'] = 'questionnaires'
  QueriesKeysEnum['serviceConfig'] = 'serviceConfig'
  QueriesKeysEnum['videoBlocks'] = 'videoBlocks'
})(QueriesKeysEnum || (QueriesKeysEnum = {}))
