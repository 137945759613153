import React, { useMemo, useState } from 'react'
import { last, isEmpty, mean, maxBy, size, isUndefined } from 'lodash'
import moment from 'moment'
import {
  Box,
  Stack,
  HStack,
  Spinner,
  useDisclosure,
  VStack,
  Grid,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import styles from '../profile.module.css'

import { useMediaQuery } from 'shared/hooks/useMediaQuery'
import { init, Form } from '@feathery/react'
import { environment } from 'environments'

import { useIndexMyInjections } from 'shared/queries/injection'
import { useIndexMyMetrics } from 'shared/queries/userMetric'
import { useCreateInjectionMutation } from 'shared/mutations/injection'
import { useActualizeMyMetricsMutation } from 'shared/mutations/userMetric'
import { useIndexMySubscriptions } from 'shared/queries/subscription'
import { useIndexMyOrders } from 'shared/queries/order'
import { LB_SIZE, USER_METRIC_METRIC_TYPES } from 'shared/constants/common'

import { Layout } from '../layout'
import { InjectionModal } from '../components/journey/modals'

import { UserProgressStatus } from '../components/userProgressStatus/UserProgressStatus'
import { ProfileTiles } from '../components/profileTiles/profileTiles'

import WeightImage from 'shared/icons/tasks/weight.svg'
import { useReadMyUserProfile } from '../../../shared/queries/userProfile'

import JourneyItemBg from 'shared/icons/journeyItemBg.svg'
import { useUserContextState } from 'shared/contexts/user-context-provider'

const Item = ({ injection, status, metrics, handleInjection, onClick, isActive, week }) => {
  const { onClose: onInjectionClose, isOpen: isInjectionOpened } = useDisclosure()
  let typeCounts = {}
  let allMetrics = []
  metrics.forEach(({ metricType }) => {
    if (!typeCounts[metricType]) {
      typeCounts[metricType] = 1
    } else {
      typeCounts[metricType]++
    }
    allMetrics.push(`${metricType}_${typeCounts[metricType]}`)
  })
  const completed = !isEmpty(injection)
  const isButtonDisabled = useMemo(() => {
    // Disable the button if status is neither 'COMPLETE' nor 'IN_DELIVERY'
    return !(status === 'COMPLETE' || status === 'IN_DELIVERY')
  }, [week, status])

  return (
    <>
      <InjectionModal
        onClose={onInjectionClose}
        isOpen={isInjectionOpened}
        handleCommit={handleInjection}
      />
      <Tooltip
        px={2}
        py={1}
        placement={'right'}
        bg={'var(--secondary-gray)'}
        rounded={8}
        fontSize={'lg'}
        label="Will be activated after receiving the order"
        isDisabled={!isButtonDisabled}
        hasArrow
      >
        <HStack
          w="100%"
          h="100px"
          alignItems="center"
          onClick={isButtonDisabled ? () => {} : onClick}
          className={isActive ? styles.active_item : undefined}
        >
          {completed ? (
            <Stack
              w="60px"
              h="60px"
              borderRadius="50%"
              bg="#BF67A6"
              border="4px solid #B12B8B"
              zIndex="1"
              cursor="pointer"
              gap={0}
              alignItems={'center'}
              position={'relative'}
            >
              <img src={JourneyItemBg} className={styles.journey_item_bg_img} />
              <Text
                color={'#89216C'}
                fontWeight={500}
                fontSize={14}
                sx={{ marginTop: '0!important' }}
              >
                week
              </Text>
              <Text
                color={'#89216C'}
                fontSize={30}
                fontWeight={600}
                margin={0}
                sx={{ marginTop: '0!important' }}
                lineHeight={'26px'}
              >
                {week}
              </Text>
            </Stack>
          ) : (
            <Stack
              w="60px"
              h="60px"
              borderRadius="50%"
              bgColor={`${isButtonDisabled ? '#AEAEAE' : '#F67BD3'}`}
              border={`4px solid ${isButtonDisabled ? '#686D76' : '#B12B8B'}`}
              zIndex="1"
              cursor={isButtonDisabled ? 'not-allowed' : 'pointer'}
              alignItems={'center'}
              gap={0}
              position={'relative'}
            >
              <img src={JourneyItemBg} className={styles.journey_item_bg_img} />
              <Text
                color={'white'}
                fontWeight={500}
                fontSize={14}
                sx={{ marginTop: '0!important' }}
              >
                week
              </Text>
              <Text
                color={'white'}
                fontSize={30}
                fontWeight={600}
                sx={{ marginTop: '0!important' }}
                lineHeight={'26px'}
              >
                {week}
              </Text>
            </Stack>
          )}

          {/* <Box
          bgColor="white"
          borderRadius="10px"
          padding={{ base: '0 10px', xl: '5px 45px 5px 35px' }}
          position="relative"
          left={{ base: '-1rem', xl: '-1.5rem' }}
          zIndex="0"
          width={{ base: '220px', xl: 'auto' }}
        >
          <Text fontWeight="800" fontSize={{ base: '1.5rem', xl: '2rem' }} letterSpacing="-1px">
            {text}
          </Text>
        </Box> */}
        </HStack>
      </Tooltip>
    </>
  )
}

export const JourneyPage = () => {
  const [chosenWeekContext, setChosenWeekContext] = useState(undefined)
  const isMobile = useMediaQuery('(max-width: 800px)')

  const { data: profile, isLoading: isProfileLoading } = useReadMyUserProfile()
  const { data: subscriptions = [], isLoading: isSubscriptionsLoading } =
    useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] })
  const subscription = subscriptions?.[0] || {}
  const { id: subscriptionId, items: products = [] } = subscription
  const { data: orders = [], isLoading: isOrdersLoading } = useIndexMyOrders(
    { subscriptionIds: [subscriptionId] },
    !isSubscriptionsLoading && !isEmpty(subscriptions) && !!subscriptionId
  )
  const product = products[0]
  const {
    data: injections = [],
    isLoading: isInjectionsLoading,
    refetch: refetchInjections,
  } = useIndexMyInjections({ subscriptionId }, !!subscriptionId)
  const {
    data: metrics = [],
    isLoading: isMetricsLoading,
    refetch: refetchMetrics,
  } = useIndexMyMetrics({ subscriptionId }, !!subscriptionId)

  const { mutate: createInjection, isLoading: insertingInjection } = useCreateInjectionMutation()
  const { mutate: addMetric, isLoading: insertingMetric } = useActualizeMyMetricsMutation()
  const isLoading =
    isInjectionsLoading ||
    isMetricsLoading ||
    isSubscriptionsLoading ||
    isOrdersLoading ||
    isProfileLoading
  const refreshing = insertingInjection || insertingMetric

  const items = useMemo(() => {
    let passedWeeks = 0
    let dates = []
    orders.map((order) => {
      const { id: orderId, updatedAt } = order
      const orderDate = moment(updatedAt)
      const currentDate = moment()
      let nextDate = orderDate.clone()
      dates = [nextDate]
      while (dates.length < 4) {
        nextDate = nextDate.clone().add(1, 'week')
        if (nextDate.isBefore(currentDate)) {
          dates.push(nextDate)
        } else {
          break
        }
      }
      dates.forEach((_, week) => {
        if (
          !isEmpty(injections.find((item) => item.orderId === orderId && item.week === week))
        ) {
          passedWeeks = passedWeeks + 1
        }
      })
    })

    return {
      passedWeeks: passedWeeks,
      activeWeeks: dates.length,
      nextIn: orders.length ? 4 - dates.length : 0,
    }
  }, [orders, injections])

  if (isLoading || isEmpty(profile))
    return (
      <Layout>
        <Box w="100%">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      </Layout>
    )
  //todo fixme
  // if (isEmpty(orders)) return null
  let daysToNextInjection

  const currentMood =
    last(metrics.filter(({ metricType }) => metricType === USER_METRIC_METRIC_TYPES.MOOD))
      ?.value ?? 'Not set'
  const { initialWeight, targetWeight } = profile
  const start = Math.floor(initialWeight * LB_SIZE.multiplier)
  const allWeightsKgs = metrics.filter(
    ({ metricType }) => metricType === USER_METRIC_METRIC_TYPES.WEIGHT
  )
  const allWeights = allWeightsKgs.map(({ value }) => +value * LB_SIZE.multiplier)
  const averageWeightPerWeek = isEmpty(allWeights)
    ? 0
    : mean(
        allWeights.map((value, index) => {
          if (index === 0) return start - value
          return allWeights[index - 1] - value
        })
      )
  const weightData = {
    weightData: {
      goal: Math.floor(targetWeight * LB_SIZE.multiplier),
      current: Math.floor(last(allWeights) ?? start),
      start,
    },
    nextTask: {
      text: '1 day Weight In',
      icon: WeightImage,
    },
    achievements: {
      targetsHit: items.passedWeeks,
      activeWeeks: items.activeWeeks,
      weeksToGo: items.nextIn,
    },
    progress: {
      amount: Math.floor(averageWeightPerWeek),
      direction: averageWeightPerWeek > 0 ? 'top' : 'bottom',
    },
  }
  return (
    <Layout>
      <VStack
        justifyContent="flex-start"
        alignItems="flex-start"
        maxW={isMobile ? '88vw' : 'auto'}
      >
        <Grid width="100%" columnGap="20px" gridTemplateColumns="1fr">
          <div className={styles.item_bg}>
            {isLoading ? (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            ) : (
              <UserProgressStatus data={weightData} />
            )}
          </div>
        </Grid>
        {!!items.activeWeeks && (
          <Box display={'flex'} alignItems={'center'} gap={2} paddingBlock={'20px'}>
            <Text fontWeight={450} fontSize={'26px'} lineHeight={'23px'} color={'#241426'}>
              {moment().format('Do MMM')}
            </Text>
            <Text fontWeight={450} fontSize={'26px'} lineHeight={'23px'} color={'#D537A8'}>
              week {items.activeWeeks}
            </Text>
          </Box>
        )}
        <Box height="auto" mt={4} width={'100%'}>
          <Box
            width={'100%'}
            position="relative"
            display={'flex'}
            gap={isMobile ? '0px' : '40px'}
            paddingLeft={isMobile ? '0px' : '20px'}
          >
            <Box
              borderRight="3px dashed #D537A8"
              marginLeft="30px"
              h="100%"
              w={0}
              position="absolute"
              zIndex="-1"
            />
            <VStack alignItems="flexStart" gap="4px" h="100%">
              {(isLoading || refreshing) && (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              )}
              {!isLoading &&
                orders.map((order, cycle) => {
                  const { id: orderId, updatedAt } = order
                  const orderInjections = injections.filter((item) => item.orderId === orderId)
                  const numberOfInjections = size(orderInjections)
                  const latestInjection = maxBy(orderInjections, (injection) =>
                    moment(injection.appliedAt).valueOf()
                  )
                  const injectionDate = latestInjection
                    ? moment(latestInjection.appliedAt)
                    : null
                  if (!latestInjection && daysToNextInjection === undefined) {
                    daysToNextInjection = 0
                  }
                  const orderDate = moment(updatedAt)
                  const currentDate = moment()
                  let nextDate = orderDate.clone()
                  let dates = [nextDate]
                  while (dates.length < 4) {
                    nextDate = nextDate.clone().add(1, 'week')
                    if (
                      daysToNextInjection === undefined &&
                      injectionDate &&
                      injectionDate.isBefore(nextDate)
                    ) {
                      daysToNextInjection = nextDate.diff(currentDate, 'days')
                    }
                    if (nextDate.isBefore(currentDate)) {
                      dates.push(nextDate)
                    } else {
                      break
                    }
                  }
                  if (
                    numberOfInjections === 4 &&
                    size(dates) === 4 &&
                    !isUndefined(daysToNextInjection)
                  )
                    daysToNextInjection = 0

                  return dates.map((date, week) => {
                    const week_ = week + 1 + 4 * cycle
                    return (
                      <Item
                        week={week_}
                        status={order.status}
                        isActive={
                          chosenWeekContext?.week + 1 + 4 * (chosenWeekContext?.cycle ?? 0) ===
                          week_
                        }
                        onClick={() =>
                          setChosenWeekContext({
                            week,
                            cycle,
                            productId: product.productId,
                            subscriptionId,
                            orderId,
                            date,
                            injection: injections.find(
                              (item) => item.orderId === orderId && item.week === week
                            ),
                          })
                        }
                        key={week}
                        injection={injections.find(
                          (item) => item.orderId === orderId && item.week === week
                        )}
                        metrics={metrics.filter(
                          (item) => item.orderId === orderId && item.week === week
                        )}
                      />
                    )
                  })
                })}
            </VStack>
            {!!chosenWeekContext && (
              <FormComponent
                formName={`Journey week ${chosenWeekContext.week + 1}`}
                {...chosenWeekContext}
                updateMetrics={async (values) => {
                  await addMetric(values, {
                    onSuccess: () => {
                      refetchMetrics()
                    },
                  })
                }}
                createInjection={async (values) => {
                  createInjection(values, {
                    onSuccess: () => {
                      refetchInjections()
                    },
                  })
                }}
              />
            )}
          </Box>
        </Box>
      </VStack>
      <VStack width="100%">
        <ProfileTiles
          itemWrapperClassname={styles.item_bg}
          days={daysToNextInjection}
          mood={currentMood}
          profile={profile}
          weights={allWeightsKgs}
          available={!isEmpty(subscription)}
        />
      </VStack>
    </Layout>
  )
}

const FormChosenDayToNumber = {
  M: 1,
  T: 2,
  W: 3,
  TH: 4,
  F: 5,
  S: 6,
  SU: 7,
}

const FormComponent = ({
  formName,
  cycle,
  week,
  subscriptionId,
  productId,
  orderId,
  updateMetrics,
  createInjection,
  date,
  injection,
}) => {
  const { user, isUserLoading } = useUserContextState()
  const isMobile = useMediaQuery('(max-width: 800px)')

  if (isUserLoading) return null
  init(environment.FEATHERY_SDK_KEY, {
    userId: user.id,
  })

  const onSubmit = async (values) => {
    if (typeof values?.submitFields?.['{{weight}}']?.value !== 'undefined') {
      await updateMetrics({
        cycle,
        week,
        metricType: USER_METRIC_METRIC_TYPES.WEIGHT,
        value: `${+values.submitFields['{{weight}}'].value / LB_SIZE.multiplier}`,
        subscriptionId,
        orderId,
      })
    }

    if (typeof values?.submitFields?.['{{injectionDay}}']?.value !== 'undefined') {
      const chosenDayOfWeekObj = values.submitFields['{{injectionDay}}'].value
      const key = Object.keys(chosenDayOfWeekObj)[0]
      const chosenDayOfWeek = chosenDayOfWeekObj[key]
      const numberChosenDayOFWeek = FormChosenDayToNumber[chosenDayOfWeek]
      const injectionDateStart = moment(date).isoWeekday()

      let plusDay = 7
      if (numberChosenDayOFWeek > injectionDateStart) {
        plusDay = numberChosenDayOFWeek - injectionDateStart
      } else if (injectionDateStart > numberChosenDayOFWeek) {
        plusDay = injectionDateStart - numberChosenDayOFWeek + 1
      }

      let injectionDate = moment(date).add(plusDay, 'days')

      if (typeof values?.submitFields?.['{{injectionTime}}']?.value !== 'undefined') {
        const injectionTimeValue = values.submitFields['{{injectionTime}}'].value[0]
        if (injectionTimeValue === 'AM') injectionDate = injectionDate.add(6, 'hours')
        if (injectionTimeValue === 'PM') injectionDate = injectionDate.add(18, 'hours')
      }

      const resultDate = injectionDate.toDate()

      await createInjection({
        cycle,
        week,
        productId,
        subscriptionId,
        orderId,
        amount: 1,
        appliedAt: resultDate,
      })
    }
  }

  return (
    isEmpty(injection) && (
      <div
        style={
          isMobile
            ? { position: 'relative', width: '100%', maxWidth: 'calc(100vw - 12% - 60px)' }
            : { position: 'relative', width: '100%' }
        }
      >
        <Form
          formName={formName}
          onSubmit={onSubmit}
          style={{ backgoundColor: 'transparent', oveflow: 'hidden' }}
          className={styles.form_wrapper}
        />
      </div>
    )
  )
}
