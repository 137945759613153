import React from 'react'
import PropTypes from 'prop-types'

import { FormikInput } from '../../../components/elements/input'
import { Dropdown } from '../../../shared/components'
import { States } from '../../../shared/constants/states'

export const AddressStep = ({ formik, fieldErrors }) => (
  <>
    <FormikInput
      name="address.streetAddress"
      label="Street Address"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <FormikInput
      name="address.addressLocality"
      label="City"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
    />
    <Dropdown
      name="address.addressRegion"
      label="State"
      showLabel
      required
      options={States}
      value={formik.values.address.addressRegion}
      errorMessage={fieldErrors?.address?.addressRegion || formik.errors?.address?.addressRegion}
      onChange={(value) => formik.setFieldValue('address.addressRegion', value)}
    />
    <FormikInput
      name="address.postalCode"
      label="Zip"
      required
      formik={formik}
      errors={fieldErrors}
    />
  </>
)

AddressStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
