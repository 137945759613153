import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useFormik } from 'formik'
import { isEmpty, trimStart } from 'lodash'
import { useToast } from '@chakra-ui/react'
import * as Yup from 'yup'
import 'yup-phone'

import { Label } from 'components/layout/label'
import { useCompleteUserProfileMutation } from 'shared/mutations/profile'
import { sleep } from 'shared/utils/sleep'
import { APP_PATHS } from 'paths'

import styles from './detailPage.module.css'
import { AddressStep } from './steps/AddressStep'
import { LastStep } from './steps/LastStep'

const getValidationSchema = (step) => {
  switch (step) {
    case 0:
      return Yup.object({
        address: Yup.object({
          addressLocality: Yup.string().required('City is required'),
          addressRegion: Yup.string().required('State is required'),
          postalCode: Yup.string()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits')
            .required('Zip is required'),
          streetAddress: Yup.string().required('Street Address is required'),
        }),
      })
    case 1:
      return Yup.object({
        gender: Yup.string().required('Sex Assigned At Birth is required'),
        initialWeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Initial Weight is required'),
        targetWeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Target Weight is required'),
        initialHeight: Yup.number()
          .nullable()
          .positive('Must be more then 0')
          .required('Initial Height is required'),
        acceptedTermsVersion: Yup.boolean().oneOf(
          [true],
          'You must agree to the terms and conditions'
        ),
        prefGenderPronouns: Yup.string(),
        isNewslettersOn: Yup.boolean(),
      })
    default:
      return Yup.object()
  }
}

export const CompleteRegistrationPage = () => {
  const navigate = useNavigate()
  const queryCache = useQueryClient()
  const { token } = useParams()
  const toast = useToast()
  const [fieldErrors, setFieldErrors] = useState({})

  const { mutate: onSetProfile } = useCompleteUserProfileMutation({
    onSuccess: async ({ token }) => {
      localStorage.setItem('AUTH_TOKEN', token)
      await queryCache.refetchQueries()
      await sleep(1000)
      await navigate(APP_PATHS.myProfile)
    },
    onError: ({ error }) => {
      const { code, fields } = error
      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      }
      setStep(0)
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  const [step, setStep] = useState(0)
  const formik = useFormik({
    initialValues: {
      token,
      address: {
        addressCountry: 'US',
        addressLocality: '',
        addressRegion: '',
        postalCode: '',
        streetAddress: '',
      },
      gender: '',
      prefGenderPronouns: '',
      isNewslettersOn: false,
    },
    validationSchema: getValidationSchema(step),
    onSubmit: (values) => {
      if (step === 1) {
        values.address.postalCode = String(values.address.postalCode)
        onSetProfile(values)
      } else {
        setStep((prev) => prev + 1)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <Label>Your Details</Label>
      <div className={styles.form}>
        {step === 0 && <AddressStep formik={formik} fieldErrors={fieldErrors} />}
        {step === 1 && <LastStep formik={formik} fieldErrors={fieldErrors} />}
      </div>

      <div className={styles.buttons}>
        <button
          type="button"
          disabled={step === 0}
          className={styles.back}
          onClick={() => setStep((prev) => prev - 1)}
        >
          Back
        </button>
        <button type="submit" className={styles.continue} disabled={!isEmpty(formik.errors)}>
          {step === 1 ? 'Submit' : 'Confirm'}
        </button>
      </div>
    </form>
  )
}
