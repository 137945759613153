import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import 'yup-phone'
import { Spinner } from '@chakra-ui/react'

import { Label } from 'components/layout/label'
import { FormikInput } from 'components/elements/input'
import { useRegisterUserMutation } from 'shared/mutations/user'

import styles from './detailPage.module.css'
import OtpModal from '../modal/otpModal/otpModal'

const validationSchema = Yup.object({
  firstName: Yup.string().required('Legal First Name is required'),
  lastName: Yup.string().required('Legal Last Name is required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])([a-zA-Z0-9`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+)(?=.{8,})/,
      'Must Contain 9 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmPassword: Yup.string()
    .required('Please re-type your password')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
})
export const AdminSignUp = () => {
  const { token } = useParams()
  const [user, setUser] = useState()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const { mutate: onRegister, isLoading } = useRegisterUserMutation({
    onSuccess: (data) => {
      setUser(data)
      setIsModalOpen(true)
    },
  })
  const formik = useFormik({
    initialValues: {
      password: '',
      firstName: '',
      lastName: '',
      acceptedTermsVersion: 1,
      invitationToken: token,
    },
    validationSchema,
    onSubmit: (values) => {
      onRegister({
        ...values,
        ...(!!token && { referralToken: token }),
      })
    },
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
        <Label>Your Details</Label>
        <div className={styles.form}>
          <FormikInput
            name="firstName"
            label="Legal First Name"
            required
            type="text"
            formik={formik}
          />
          <FormikInput
            name="lastName"
            label="Legal Last Name"
            required
            type="text"
            formik={formik}
          />
          <FormikInput
            name="password"
            label="Password"
            required
            type="password"
            formik={formik}
          />
          <FormikInput
            name="confirmPassword"
            label="Confirm Password"
            required
            type="password"
            formik={formik}
          />
        </div>

        <div className={styles.buttons}>
          <button
            type="submit"
            className={styles.continue}
            disabled={!isEmpty(formik.errors) || isLoading || !isEmpty(user)}
          >
            Create {isLoading && <Spinner ml={3} size="md" />}
          </button>
        </div>
      </form>
      <OtpModal isOpen={isModalOpen} user={user} />
    </>
  )
}
