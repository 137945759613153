import React from 'react'
export const CircleSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      {...args}
    >
      <path
        d="M14.8237 4.24414C8.84491 4.24414 4 8.97057 4 14.8031C4 20.6357 8.84491 25.3621 14.8237 25.3621H14.866C20.8448 25.3621 25.6897 20.6357 25.6897 14.8031C25.6897 8.97057 20.8448 4.24414 14.866 4.24414H14.8237Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.8663 11.0687C16.9776 11.0687 18.6914 12.7438 18.6914 14.8003C18.6914 16.8568 16.9743 18.5319 14.8663 18.5319C12.7583 18.5319 11.0412 16.8568 11.0412 14.8003C11.0412 12.7438 12.7583 11.0687 14.8663 11.0687ZM14.8663 9.47949C11.8525 9.47949 9.41211 11.8634 9.41211 14.8003C9.41211 17.7373 11.8557 20.1211 14.8663 20.1211C17.8769 20.1211 20.3205 17.7373 20.3205 14.8003C20.3205 11.8634 17.8769 9.47949 14.8663 9.47949Z"
        fill="currentColor"
      />
      <path
        d="M14.8659 8.68154C18.3294 8.68154 21.1379 11.4214 21.1379 14.8002C21.1379 18.1789 18.3294 20.9188 14.8659 20.9188C11.4025 20.9188 8.59393 18.1789 8.59393 14.8002C8.59393 11.4214 11.4025 8.68154 14.8659 8.68154ZM14.8659 7.09229C10.5097 7.09229 6.96484 10.5505 6.96484 14.8002C6.96484 19.0498 10.5097 22.508 14.8659 22.508C19.2221 22.508 22.767 19.0498 22.767 14.8002C22.767 10.5505 19.2221 7.09229 14.8659 7.09229Z"
        fill="currentColor"
      />
    </svg>
  )
}
