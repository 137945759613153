import './App.css'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { APP_PATHS } from 'paths'
import { Helmet } from 'react-helmet-async'

import {
  HomePage,
  TreatmentIndex,
  TreatmentPlansPage,
  HowItWorksPage,
  FaqPage,
  WhyMaionPage,
  OrderPayment,
  PhysicianPage,
  NewAppointmentPage,
  MyProfilePage,
  Referrals,
  TermsAndConditions,
  ContactsPage,
} from './pages'
import { Header } from './components/layout/header'
import { Footer } from './components/layout/footer'
import { LoginPage } from './features/registration/page'
import { RegistrationPage } from './features/registration/registrationPage'
import { SchedulePage } from './features/schedule/schedulePage'
import { PickDate } from './features/schedule/pickDate'
import { ScheduleResult } from './features/schedule/scheduleResult'
import { AddToCalendar } from './features/schedule/addToCalendar'
import { BasketPage } from './features/profile'
import { OrderUploadId } from './features/order/orderUploadId'
import { ConfirmEmail } from './pages/ConfirmEmail'
import { BottomNavigation } from './components/layout/bottomNavigation/bottomNavigation'
import { AuthWeb2ProviderPage } from './pages/AuthWeb2Provider'
import { OrderSubscribe } from './pages/OrderSubscribe'
import { AdminSignUp } from './features/registration/adminRegistration'
import { isAndroid } from './shared/utils/device'
import {
  MetaTitlesMap,
  MetaKeywordsList,
  MetaDescription,
} from './shared/constants/metaTitlesMap'
import { AskBot } from './components/elements/askBot'
// import { WelcomePage } from './pages/welcome'
import { FinishRegistrationPage } from './features/registration/finishRegistrationPage'
import { ResetPassword } from './features/resetPassword/page'
import { SingleProduct } from './features/treatments/singleProduct'
import { CompleteRegistrationPage } from './features/registration/completeRegistrationPage'
import { Banner } from 'components/layout/banner'
import { VerifyPhoneNumberPage } from './features/registration/verifyPhoneNumberPage'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'
import { PrivacyPolicy } from 'pages/PrivacyPolicy'
import { LoginDev, isDevEnv, isDevLoggedInChecker } from 'LoginDev'

function App() {
  const { user } = useUserContextState()
  const location = useLocation()
  const [title, setTitle] = useState('')
  const isDevEnvironment = isDevEnv()
  const [isDevLogIn, setIsDevLogIn] = useState(!isDevEnvironment)

  useEffect(() => {
    if (!isAndroid()) {
      document.addEventListener(
        'touchmove',
        (event) => event.scale !== 1 && event.preventDefault(),
        { passive: false }
      )
    }

    // const isDark = window.matchMedia('(prefers-color-scheme: dark)')
    // const link = document.createElement('link')
    // const oldLink = document.getElementById('dynamic-favicon')
    // link.id = 'dynamic-favicon'
    // link.rel = 'shortcut icon'
    // link.href = isDark.matches ? WhiteLogo : DarkLogo
    // if (oldLink) {
    //   document.head.removeChild(oldLink)
    // }
    // document.head.appendChild(link)
  }, [])

  useEffect(() => {
    const pageTitle = MetaTitlesMap[location.pathname] || ''
    setTitle(pageTitle)
  }, [location])

  useEffect(() => {
    if (isDevEnvironment && !isDevLogIn) {
      setIsDevLogIn(isDevLoggedInChecker())
    }
  }, [])

  if (isDevEnvironment && !isDevLogIn) {
    return <LoginDev siteName={'Maion'} onLogin={() => setIsDevLogIn(true)} />
  }

  const pageKeywords = [title, ...MetaKeywordsList].filter(Boolean).join(', ')

  return (
    <div className={!isEmpty(user) ? 'App_logged_in' : 'App'}>
      <Helmet>
        <title>
          {title
            ? `Maion - ${title}`
            : 'Maion - Leading Innovations in GLP-1 and Weight Loss Peptides'}
        </title>
        <meta name="description" content={MetaDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta property="og:site_name" content="Maion" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            title
              ? `Maion - ${title}`
              : 'Maion - Leading Innovations in GLP-1 and Weight Loss Peptides'
          }
        />
        <meta property="og:description" content={MetaDescription} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:site" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="article:tag" content={pageKeywords} />
      </Helmet>
      <Banner />
      <Header />
      <Routes>
        <Route path={APP_PATHS.home} element={<HomePage />} />
        <Route path={APP_PATHS.welcome} element={<HomePage />} />
        <Route path={APP_PATHS.treatment}>
          <Route index element={<TreatmentIndex />} />
          <Route path={APP_PATHS.treatmentPlans} element={<TreatmentPlansPage />} />
        </Route>

        <Route path={APP_PATHS.product} element={<SingleProduct />} />
        <Route path={APP_PATHS.howitWorks} element={<HowItWorksPage />} />
        <Route path={APP_PATHS.faq} element={<FaqPage />} />
        <Route path={APP_PATHS.whyMaion} element={<WhyMaionPage />} />
        <Route path={APP_PATHS.contact} element={<ContactsPage />} />
        <Route path={APP_PATHS.orderPayment} element={<OrderPayment />} />
        <Route path={APP_PATHS.orderSubscription} element={<OrderSubscribe />} />
        <Route path={APP_PATHS.orderUploadId} element={<OrderUploadId />} />
        <Route path={APP_PATHS.confirmEmail} element={<ConfirmEmail />} />
        <Route path={APP_PATHS.inviteByEmail} element={<AdminSignUp />} />
        <Route path={APP_PATHS.physician} element={<PhysicianPage />} />
        <Route path={APP_PATHS.newAppointment} element={<NewAppointmentPage />} />
        <Route path={APP_PATHS.myProfile}>
          <Route index element={<MyProfilePage.JourneyPage />} />
          <Route path={APP_PATHS.basket} element={<BasketPage />} />
          <Route path={APP_PATHS.myProfileAboutPage} element={<MyProfilePage.AboutPage />} />
          <Route path={APP_PATHS.myProfileBillingPage} element={<MyProfilePage.BillingPage />} />
          <Route path={APP_PATHS.myProfileUserPage} element={<MyProfilePage.UserPage />} />
          <Route path={APP_PATHS.myProfileReturnsPage} element={<MyProfilePage.ReturnsPage />} />
          <Route
            path={APP_PATHS.myProfileRenewPrescription}
            element={<MyProfilePage.RenewPrescriptionPage />}
          />
          <Route
            path={APP_PATHS.myProfileReferralPage}
            element={<MyProfilePage.ReferralPage />}
          />
          <Route
            path={APP_PATHS.myProfileMessagesPage}
            element={<MyProfilePage.MessagesPage />}
          />
        </Route>
        <Route path={APP_PATHS.registration}>
          <Route index element={<LoginPage />} />
          <Route path={APP_PATHS.registrationWithToken} element={<LoginPage />} />
          <Route path={APP_PATHS.registrationStep1} element={<RegistrationPage />} />
          <Route path={APP_PATHS.finishRegistration} element={<FinishRegistrationPage />} />
          <Route path={APP_PATHS.completeRegistration} element={<CompleteRegistrationPage />} />
          <Route path={APP_PATHS.verifyPhoneNumber} element={<VerifyPhoneNumberPage />} />
        </Route>
        <Route path={APP_PATHS.schedule}>
          <Route index element={<SchedulePage />} />
          <Route path={APP_PATHS.schedulePickDate} element={<PickDate />} />
          <Route
            path={APP_PATHS.scheduleDetails}
            element={<RegistrationPage isFromScheduler />}
          />
          <Route path={APP_PATHS.scheduleResult} element={<ScheduleResult />} />
          <Route path={APP_PATHS.scheduleCalendar} element={<AddToCalendar />} />
        </Route>
        <Route path={APP_PATHS.referrals} element={<Referrals />} />
        <Route path={APP_PATHS.termsAndConditions} element={<TermsAndConditions />} />
        <Route path={APP_PATHS.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={APP_PATHS.authWeb2Provider} element={<AuthWeb2ProviderPage />} />
        <Route path={APP_PATHS.resetPassword} element={<ResetPassword />} />
      </Routes>
      {location.pathname === APP_PATHS.faq ? (
        <div />
      ) : (
        <AskBot initialClosed closeOnRouteChange />
      )}
      <Footer />
      <BottomNavigation />
    </div>
  )
}

export default App
